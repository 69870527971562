import styled from "styled-components";
import { Box, Flex, Avatar, Text, Divider } from '@chakra-ui/react'
import { NavLink } from "react-router-dom";
import { useState } from "react";

const Sidebars = styled.div`
    margin: 0;
    padding: 10px;
    width: 250px;
    background-color: #1A202C;
    position: fixed;
    height: 100%;
    overflow: auto;
    ::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 700px) {
        width: 100%;
        height: auto;
        position: relative;
    }
`

const A = styled.a`
    display: block;
    color: #ffffff;
    padding: 16px;
    text-decoration: none;
    background-color: transparent;

    &.active {
        background-color: #3182CE;
    }

    &:hover {
        cursor: pointer;
    }

    &.visible {
        display: none;
    }

    @media screen and (max-width: 700px) {
        float: left;
    }

    @media screen and (max-width: 400px) {
        text-align: center;
        float: none;
    }
`

const PurchaseDropdownContainer = styled.div`
  display: block;
  background-color: transparent;
  color: white;
  padding-left: 8px;
  display: ${({ purchaseDropdown }) => (purchaseDropdown ? 'block' : 'none')};
  transition: 350ms;
  z-index: 10;
`;

const SaleDropdownContainer = styled.div`
  display: block;
  background-color: transparent;
  color: white;
  padding-left: 8px;
  display: ${({ saleDropdown }) => (saleDropdown ? 'block' : 'none')};
  transition: 350ms;
  z-index: 10;
`;

const BudgetDropdownContainer = styled.div`
  display: block;
  background-color: transparent;
  color: white;
  padding-left: 8px;
  display: ${({ budgetDropdown }) => (budgetDropdown ? 'block' : 'none')};
  transition: 350ms;
  z-index: 10;
`;

const ConfigurationDropdownContainer = styled.div`
  display: block;
  background-color: transparent;
  color: white;
  padding-left: 8px;
  display: ${({ configurationDropdown }) => (configurationDropdown ? 'block' : 'none')};
  transition: 350ms;
  z-index: 10;
`;

/*&:hover {
        background-color: #555;
        color: white;
        transition: 0.5s all ease;
        cursor: pointer;
    }*/



const Sidebar = () => {
    let [purchaseDropdown, setPurchaseDropdown] = useState(false)
    let [saleDropdown, setSaleDropdown] = useState(false)
    let [budgetDropdown, setBudgetDropdown] = useState(false)
    let [configurationDropdown, setConfigurationDropdown] = useState(false)

    const showDropdown = (children) => {
        if (children.children[1] === "Compras") {
            setPurchaseDropdown(!purchaseDropdown)
            setSaleDropdown(false)
            setBudgetDropdown(false)
            setConfigurationDropdown(false)
        } else if (children.children[1] === "Ventas") {
            setSaleDropdown(!saleDropdown)
            setPurchaseDropdown(false)
            setBudgetDropdown(false)
            setConfigurationDropdown(false)
        } else if (children.children[1] === "Presupuestos") {
            setBudgetDropdown(!budgetDropdown)
            setPurchaseDropdown(false)
            setSaleDropdown(false)
            setConfigurationDropdown(false)
        } else if (children.children[1] === "Configuraciones") {
            setConfigurationDropdown(!configurationDropdown)
            setPurchaseDropdown(false)
            setSaleDropdown(false)
            setBudgetDropdown(false)
        } else if (children.children[1] === "Proveedores" || children.children[1] === "Nueva compra" || 
        children.children[1] === "Listado de compras" || children.children[1] === "Cuenta corriente de proveedores" ||
        children.children[1] === "Planificar compra") {
            setPurchaseDropdown(true)
            setSaleDropdown(false)
            setBudgetDropdown(false)
            setConfigurationDropdown(false)
        } else if(children.children[1] === "Movimientos" || children.children[1] === "Listado de remitos" || children.children[1] === "Listado de recibos") {
            setPurchaseDropdown(false)
            setSaleDropdown(true)
            setBudgetDropdown(false)
            setConfigurationDropdown(false)
        } else if(children.children[1] === "Nuevo presupuesto" || children.children[1] === "Listado de presupuestos") {
            setPurchaseDropdown(false)
            setSaleDropdown(false)
            setBudgetDropdown(true)
            setConfigurationDropdown(false)
        } else if(children.children[1] === "Categorías" || children.children[1] === "Subcategorías") {
            setPurchaseDropdown(false)
            setSaleDropdown(false)
            setBudgetDropdown(false)
            setConfigurationDropdown(true)
        }  else {
            setPurchaseDropdown(false)
            setSaleDropdown(false)
            setBudgetDropdown(false)
            setConfigurationDropdown(false)
        }
    }
    
    function Link({ as, className, href, to, children }) {
        return (
          <A
            as={as}
            activeclassname="active"
            className={className}
            href={href}
            to={to}
            onClick={e => showDropdown({children})}
          >
            {children}
          </A>
        );
      }
    

    return(
        
        <Sidebars>
            <Flex padding="10px">
                <Avatar/>
                <Box ml="3">
                    <Text fontWeight="bold" color="white">Administrador</Text>
                    <Text fontSize="sm" color="white">Administrador</Text>
                </Box>     
            </Flex>

            <Divider w="230px"/>

            <br/>

            <Link as={NavLink} to="products">
                <i className="bi bi-shop icon-sidebar"></i>
                Productos
            </Link>
            <Link as={NavLink} to="customers">
                <i className="bi bi-file-person icon-sidebar"></i>
                Clientes
            </Link>
            <Link>
                <i className="bi bi-cart4 icon-sidebar"></i>
                Compras
                <i className="bi bi-caret-down-fill icon-sidebar-down"></i>
            </Link>
            <PurchaseDropdownContainer purchaseDropdown={purchaseDropdown}>
                <Link as={NavLink} to="purchaseAdd">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Nueva compra
                </Link>
                <Link as={NavLink} to="purchases">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Listado de compras
                </Link>
                <Link as={NavLink} to="supplierCurrentAccount">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Cuenta corriente de proveedores
                </Link>
                <Link as={NavLink} to="suppliers">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Proveedores
                </Link>
                <Link as={NavLink} to="purchasePlan">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Planificar compra
                </Link>
            </PurchaseDropdownContainer>
            <Link>
                <i className="bi bi-shop icon-sidebar"></i>
                Ventas
                <i className="bi bi-caret-down-fill icon-sidebar-down"></i>
            </Link>
            <SaleDropdownContainer saleDropdown={saleDropdown}>
                <Link as={NavLink} to="sales">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Movimientos
                </Link>
                <Link as={NavLink} to="despatchs">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Listado de remitos
                </Link>
                <Link as={NavLink} to="receipts">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Listado de recibos
                </Link>
            </SaleDropdownContainer>
            <Link>
                <i className="bi bi-card-checklist icon-sidebar"></i>
                Presupuestos
                <i className="bi bi-caret-down-fill icon-sidebar-down"></i>
            </Link>
            <BudgetDropdownContainer budgetDropdown={budgetDropdown}>
                <Link as={NavLink} to="budgetAdd">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Nuevo presupuesto
                </Link>
                <Link as={NavLink} to="budgets">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Listado de presupuestos
                </Link>
            </BudgetDropdownContainer>
            <Link>
                <i className="bi bi-gear icon-sidebar"></i>
                Configuraciones
                <i className="bi bi-caret-down-fill icon-sidebar-down"></i>
            </Link>
            <ConfigurationDropdownContainer configurationDropdown={configurationDropdown}>
                <Link as={NavLink} to="categories">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Categorías
                </Link>
                <Link as={NavLink} to="subcategories">
                    <i className="bi bi-dot icon-dropdown"></i>
                    Subcategorías
                </Link>
            </ConfigurationDropdownContainer>
            <Link as={NavLink} to="logout">
                <i className="bi bi-box-arrow-left icon-sidebar"></i>
                Cerrar sesión
            </Link>
        </Sidebars>
    )
}

export default Sidebar