import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure,
    Button, Tooltip } from '@chakra-ui/react'
import { BsPrinter } from "react-icons/bs"
import { useRef, useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import PDFNoOficial from './PDFNoOficial'
import PDFInvoiceA from './PDFInvoiceA'
import PDFInvoiceB from './PDFInvoiceB'
import PDFCNNoOficial from './PDFCNNoOficial'
import PDFCNA from './PDFCNA'
import PDFCNB from './PDFCNB'
import PDFReceipt from './PDFReceipt'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import moment from 'moment'
var QRCode = require('qrcode')

const PrintPDFVoucher = ({ idVoucher, voucherLetter }) => {
    const { isOpen: isOpenNoOficial, onOpen: onOpenNoOficial, onClose: onCloseNoOficial } = useDisclosure()
    const { isOpen: isOpenInvoiceA, onOpen: onOpenInvoiceA, onClose: onCloseInvoiceA } = useDisclosure()
    const { isOpen: isOpenInvoiceB, onOpen: onOpenInvoiceB, onClose: onCloseInvoiceB } = useDisclosure()
    const { isOpen: isOpenCreditNoteNoOficial, onOpen: onOpenCreditNoteNoOficial, onClose: onCloseCreditNoteNoOficial } = useDisclosure()
    const { isOpen: isOpenCreditNoteA, onOpen: onOpenCreditNoteA, onClose: onCloseCreditNoteA } = useDisclosure()
    const { isOpen: isOpenCreditNoteB, onOpen: onOpenCreditNoteB, onClose: onCloseCreditNoteB } = useDisclosure()
    const { isOpen: isOpenReceipt, onOpen: onOpenReceipt, onClose: onCloseReceipt } = useDisclosure()
    const initialRefNoOficial = useRef(null)
    let [ form, setForm ] = useState({})

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")
        
        moment.locale("es")
        
        if (voucherLetter === "No oficial") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/" + idVoucher, {
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                }
            })
            .then(response => response.json())
            .then(data => {
                setForm(form = {
                    customerName: data.customer.name,
                    customerStreet: data.customer.street,
                    customerIva: data.customer.ivaName,
                    customerCity: data.customer.city,
                    customerState: data.customer.stateName,
                    customerCuit: data.customer.cuit,
                    invoiceNumber: data.invoiceNumber,
                    date: moment(data.date).add(3,"h").format("L"),
                    products: data.products,
                    grossPrice: data.grossPrice,
                    ivaPrice: data.ivaPrice,
                    price: data.price,
                    observations: data.observations
                })
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
            
            onOpenNoOficial()
        } else if(voucherLetter === "A") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/" + idVoucher, {
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                }
            })
            .then(response => response.json())
            .then(data => {
                const dateAfip = moment(data.dateAfip).add(1, 'd')
                const caeExpiration = moment(data.caeExpiration).add(1, 'd')
                let qr = ""
                QRCode.toDataURL(data.base64, function (err, url) {
                    qr = url
                })
                setForm(form = {
                    customerName: data.customer.name,
                    customerStreet: data.customer.street,
                    customerIva: data.customer.ivaName,
                    customerCity: data.customer.city,
                    customerState: data.customer.stateName,
                    customerCuit: data.customer.cuit,
                    invoiceNumber: data.invoiceNumberAfip,
                    dateAfip: dateAfip.format("L"),
                    products: data.products,
                    grossPrice: data.grossPrice,
                    ivaPrice: data.ivaPrice,
                    price: data.price,
                    caeExpiration: caeExpiration.format("L"),
                    cae: data.cae,
                    qr: qr
                })
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
            
            onOpenInvoiceA()
        } else if(voucherLetter === "B") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/" + idVoucher, {
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                }
            })
            .then(response => response.json())
            .then(data => {
                const dateAfip = moment(data.dateAfip).add(1, 'd')
                const caeExpiration = moment(data.caeExpiration).add(1, 'd')
                let qr = ""
                QRCode.toDataURL(data.base64, function (err, url) {
                    qr = url
                })
                setForm(form = {
                    customerName: data.customer.name,
                    customerStreet: data.customer.street,
                    customerIva: data.customer.ivaName,
                    customerCity: data.customer.city,
                    customerState: data.customer.stateName,
                    customerCuit: data.customer.cuit,
                    invoiceNumber: data.invoiceNumberAfip,
                    dateAfip: dateAfip.format("L"),
                    products: data.products,
                    grossPrice: data.grossPrice,
                    ivaPrice: data.ivaPrice,
                    price: data.price,
                    caeExpiration: caeExpiration.format("L"),
                    cae: data.cae,
                    qr: qr
                })
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
            
            onOpenInvoiceB()
        } else if(voucherLetter === "N.C. No oficial") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/creditNote/" + idVoucher, {
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                }
            })
            .then(response => response.json())
            .then(data => {
                setForm(form = {
                    customerName: data.customer.name,
                    customerStreet: data.customer.street,
                    customerIva: data.customer.ivaName,
                    customerCity: data.customer.city,
                    customerState: data.customer.stateName,
                    customerCuit: data.customer.cuit,
                    creditNoteNumber: data.creditNoteNumber,
                    despatchNumber: data.despatchNumber,
                    date: moment(data.date).add(3,"h").format("L"),
                    products: data.products,
                    grossPrice: data.grossPrice,
                    ivaPrice: data.ivaPrice,
                    price: data.price,
                })
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
            
            onOpenCreditNoteNoOficial()
        } else if(voucherLetter === "N.C. A") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/creditNote/" + idVoucher, {
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                }
            })
            .then(response => response.json())
            .then(data => {
                const dateAfip = moment(data.dateAfip).add(1, 'd')
                const caeExpiration = moment(data.caeExpiration).add(1, 'd')
                let qr = ""
                QRCode.toDataURL(data.base64, function (err, url) {
                    qr = url
                })
                setForm(form = {
                    customerName: data.customer.name,
                    customerStreet: data.customer.street,
                    customerIva: data.customer.ivaName,
                    customerCity: data.customer.city,
                    customerState: data.customer.stateName,
                    customerCuit: data.customer.cuit,
                    creditNoteNumber: data.creditNoteNumberAfip,
                    despatchNumber: data.despatchNumber,
                    dateAfip: dateAfip.format("L"),
                    products: data.products,
                    grossPrice: data.grossPrice,
                    ivaPrice: data.ivaPrice,
                    price: data.price,
                    caeExpiration: caeExpiration.format("L"),
                    cae: data.cae,
                    qr: qr
                })
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
            
            onOpenCreditNoteA()
        } else if(voucherLetter === "N.C. B") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/creditNote/" + idVoucher, {
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                }
            })
            .then(response => response.json())
            .then(data => {
                const dateAfip = moment(data.dateAfip).add(1, 'd')
                const caeExpiration = moment(data.caeExpiration).add(1, 'd')
                let qr = ""
                QRCode.toDataURL(data.base64, function (err, url) {
                    qr = url
                })
                setForm(form = {
                    customerName: data.customer.name,
                    customerStreet: data.customer.street,
                    customerIva: data.customer.ivaName,
                    customerCity: data.customer.city,
                    customerState: data.customer.stateName,
                    customerCuit: data.customer.cuit,
                    creditNoteNumber: data.creditNoteNumberAfip,
                    despatchNumber: data.despatchNumber,
                    dateAfip: dateAfip.format("L"),
                    products: data.products,
                    grossPrice: data.grossPrice,
                    ivaPrice: data.ivaPrice,
                    price: data.price,
                    caeExpiration: caeExpiration.format("L"),
                    cae: data.cae,
                    qr: qr
                })
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
            
            onOpenCreditNoteB()
        } else if (voucherLetter === "Recibo") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/receipt/" + idVoucher, {
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                }
            })
            .then(response => response.json())
            .then(data => {
                setForm(form = {
                    customerName: data.customer.name,
                    customerStreet: data.customer.street,
                    customerIva: data.customer.ivaName,
                    customerCity: data.customer.city,
                    customerState: data.customer.stateName,
                    customerCuit: data.customer.cuit,
                    receiptNumber: data.receiptNumber,
                    date: moment(data.date).add(3,"h").format("L"),
                    payments: data.payments,
                    observations: data.observations,
                    debt: "-",
                    paid: data.amount,
                    balance: "-"
                })
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
            
            onOpenReceipt()
        }
    }

    return (
        <>
            <Tooltip label='Imprimir'><Button size="sm" colorScheme='messenger' onClick={handleOpen}><BsPrinter/></Button></Tooltip>

            <Modal
                initialFocusRef={initialRefNoOficial}
                isOpen={isOpenNoOficial}
                onClose={onCloseNoOficial}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Comprobante</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFNoOficial {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                isOpen={isOpenInvoiceA}
                onClose={onCloseInvoiceA}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Factura</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFInvoiceA {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                isOpen={isOpenInvoiceB}
                onClose={onCloseInvoiceB}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Factura</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFInvoiceB {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                isOpen={isOpenCreditNoteNoOficial}
                onClose={onCloseCreditNoteNoOficial}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nota de crédito</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFCNNoOficial {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                isOpen={isOpenCreditNoteA}
                onClose={onCloseCreditNoteA}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nota de crédito</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFCNA {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                isOpen={isOpenCreditNoteB}
                onClose={onCloseCreditNoteB}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nota de crédito</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFCNB {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                isOpen={isOpenReceipt}
                onClose={onCloseReceipt}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Recibo</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFReceipt {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default PrintPDFVoucher