import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure,
    Button, MenuItem, useToast, Stack, InputGroup, InputLeftAddon, Select, Flex, Spacer } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import PDFProductsStock from './PDFProductsStock'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const PrintPDFProductsSubcategory = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { isOpen: isOpenChoose, onOpen: onOpenChoose, onClose: onCloseChoose } = useDisclosure()
    const initialRefChoose = useRef(null)
    let [ form, setForm ] = useState({})
    const [categories, setCategories] = useState([])
    const [subcategories, setSubcategories] = useState([])
    const [subcategory, setSubcategory] = useState(null)
    const toast = useToast()

    const navigate = useNavigate();

    const handleOpenChoose = () => {
        const accessToken = localStorage.getItem("user")
        
        fetch("https://api.sistemacorralonbianchi.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCategories = data.map(category => {
                return { 
                    id: category._id,
                    name: category.name
                }
            })
            setCategories(arrCategories)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        onOpenChoose()
    }

    const handleChange = (e) => {
        const accessToken = localStorage.getItem("user")

        if (e.target.value === "") {
            setSubcategories([])
            return
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/subcategory/category/" + e.target.value, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrSubcategories = data.map(subcategory => {
                return { 
                    id: subcategory._id,
                    name: subcategory.name
                }
            })
            setSubcategories(arrSubcategories)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        if (subcategory === "" || subcategory === null) {
            toast({
                title: "Debe seleccionar una subcategoría",
                status: "error",
                duration: 3000,
                isClosable: true
            });

            return
        }
        
        fetch("https://api.sistemacorralonbianchi.com.ar/api/product/allProducts/false/" + subcategory, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            setForm(form = {
                products: data
            })
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        onOpen()
    }

    return (
        <>
            <MenuItem onClick={handleOpenChoose}>Stock por subcategoría</MenuItem>

            <Modal
                initialFocusRef={initialRefChoose}
                isOpen={isOpenChoose}
                onClose={onCloseChoose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Stock por subcategoría</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>

                        <Stack spacing={4}>

                            <InputGroup>
                                <InputLeftAddon children='Categoría' width={127} />
                                <Select placeholder='-Selecciona-' onChange={(e) => handleChange(e)}>
                                {categories.map(category => {
                                    return (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                    )})}
                                </Select>
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Subcategoría' width={127} />
                                <Select placeholder='-Selecciona-' onChange={(e) => setSubcategory(e.target.value)}>
                                {subcategories.map(subcategory => {
                                    return (
                                    <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
                                )})}
                                </Select>
                            </InputGroup>

                        </Stack>
                        <Flex p={4}>
                            <Spacer/>
                            <Button colorScheme='blue' mr={3} onClick={(e) => handleOpen()}>Generar</Button>
                            <Button onClick={onCloseChoose}>Cancelar</Button>
                        </Flex>
                        
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Listado de productos</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFProductsStock {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default PrintPDFProductsSubcategory