import { Box, Stack, HStack, InputGroup, InputLeftAddon, Select, AlertDialog, AlertDialogBody, 
    AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useDisclosure,
    useToast, Input, Textarea, Spacer, Button, Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Tooltip, Card } from '@chakra-ui/react'
import { TYPES } from '../actions/cartAction'
import { useTable, usePagination } from "react-table"
import { DeleteIcon } from '@chakra-ui/icons'
import { useState, useReducer, useRef, useEffect } from 'react'
import { cartInitialState, cartReducer } from '../reducers/cartReducer'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import SaleProductAdd from './SaleProductAdd'
import CustomerSearch from './CustomerSearch'
import useColumnsSaleAdd from "../hooks/useColumnsSaleAdd"
import DatePicker, { registerLocale } from "react-datepicker";
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')

const BudgetAdd = () => {
    const [stateReducer, dispatch] = useReducer(cartReducer, cartInitialState)
    const { data } = stateReducer
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm()
    const [paymentMethods, setPaymentMethods] = useState([])
    const [budgetDate, setBudgetDate] = useState(new Date())
    const [grossPrice, setGrossPrice] = useState(parseFloat(0).toFixed(2))
    const [ivaPrice, setIvaPrice] = useState(parseFloat(0).toFixed(2))
    const [price, setPrice] = useState(parseFloat(0).toFixed(2))
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const toast = useToast()
    let [idCustomer, setIdCustomer] = useState(0)
    const [submit, setSubmit] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.sistemacorralonbianchi.com.ar/api/paymentMethod", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrPaymetMethods = data.map(paymentMethod => {
                return {
                    id: paymentMethod._id,
                    name: paymentMethod.name
                }
            })
            setPaymentMethods(arrPaymetMethods)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        fetch("https://api.sistemacorralonbianchi.com.ar/api/budget/get/budget/number", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const budget = data.map(budget => {
                return { 
                    budgetNumber: budget.budgetNumber
                }
            })
            setValue("budgetNumber", budget[0].budgetNumber)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }, [submit])

    const columns = useColumnsSaleAdd();

    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["_id", "serialNumber"]} }, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state
      } = table;

    const onSubmit = () => {
        if (data.length === 0){
            toast({
                title: "No hay productos cargados",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (budgetDate === null){
            toast({
                title: "Seleccione una fecha",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        onOpen()
    }

    const confirmBudget = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("en")

        const products = []

        for (let i=0; i < data.length; i++) {
            products.push({
                _id: data[i]._id,
                id: data[i].id,
                quantity: parseFloat(data[i].quantity),
                discount: parseFloat(data[i].discount),
                warranty: data[i].warranty,
                avgIva: parseFloat(data[i].avgIva),
                unitPrice: parseFloat(data[i].unitPrice),
                totalPrice: parseFloat(data[i].totalPrice)
            })
        }
        
        const budget = {
            customer: idCustomer,
            budgetNumber: getValues("budgetNumber"),
            validity: parseInt(getValues("validity")),
            date: moment(budgetDate).format('L'),
            paymentMethod: getValues("paymentMethod"),
            observations: getValues("observations") || "",
            grossPrice: parseFloat(grossPrice),
            ivaPrice: parseFloat(ivaPrice),
            price: parseFloat(price),
            products: products
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/budget", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(budget)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        reset()
        clearCart()
        onClose()
        setSubmit(!submit)

        toast({
            title: "El presupuesto se cargó correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });
    }

    const handleSelect = (idNewCustomer) => {
        const accessToken = localStorage.getItem("user")
        
        fetch("https://api.sistemacorralonbianchi.com.ar/api/customer/" + idNewCustomer, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setIdCustomer(idNewCustomer)
            setValue("customer", data.name)
            setValue("iva", data.iva)
            setValue("cuit", data.cuit)
            setValue("street", data.street)
            setValue("city", data.city)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const addToCart = (product) => {
        let totalPrice
        const unitPrice = parseFloat(product.price) / ((parseFloat(product.avgIva) / 100) + 1)
        if (parseFloat(product.discount) > 0) {
            const subtotal = parseFloat(unitPrice) - (parseFloat(unitPrice) * parseFloat(product.discount) / 100)
            totalPrice = parseFloat(product.quantity) * parseFloat(subtotal)
        } else {
            totalPrice = parseFloat(product.quantity) * parseFloat(unitPrice)
        }
        product = {
            ...product,
            unitPrice: parseFloat(unitPrice).toFixed(2),
            totalPrice: parseFloat(totalPrice).toFixed(2)
        }
        
        dispatch({ type:TYPES.ADD_TO_CART, payload: product })
        calculatingPrices(product, true)
    }

    const clearCart = () => {
        dispatch({ type:TYPES.CLEAR_CART })

        setGrossPrice(parseFloat(0).toFixed(2))
        setIvaPrice(parseFloat(0).toFixed(2))
        setPrice(parseFloat(0).toFixed(2))

        onClose()
    }

    const removeFromCart = (product) => {
        dispatch({ type:TYPES.REMOVE_ONE_FROM_CART, payload: product.id })
        calculatingPrices(product, false)
    }

    const calculatingPrices = (product, add) => {
        let sumGrossPrice = 0
        let sumIvaPrice = 0
        if (product) {
            for (let i=0; i < data.length; i++) {
                if (data[i].id === product.id) continue
                sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(data[i].totalPrice) * parseFloat(data[i].avgIva) / 100)
            }

            if (add === true) {
                const productSearch = data.find(obj => obj.id === product.id)
                if (productSearch) {
                    const quantityProduct = parseFloat(productSearch.quantity) + parseFloat(product.quantity)
                    const grossPriceProduct = parseFloat(quantityProduct) * parseFloat(product.unitPrice)
                    const ivaPriceProduct = parseFloat(grossPriceProduct) * parseFloat(product.avgIva) / 100
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(grossPriceProduct)
                    sumIvaPrice = parseFloat(sumIvaPrice) + parseFloat(ivaPriceProduct)
                } else {
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(product.totalPrice)
                    sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(product.totalPrice) * parseFloat(product.avgIva) / 100)
                }
            }
        }
        setGrossPrice(parseFloat(sumGrossPrice).toFixed(2))
        setIvaPrice(parseFloat(sumIvaPrice).toFixed(2))
        setPrice(parseFloat(sumGrossPrice + sumIvaPrice).toFixed(2))
    }

    return(
        <>
            <Box className="content">

                <form onSubmit={handleSubmit(onSubmit)} width='500px'>
                    <Stack spacing={4}>

                        <Card align="center" variant="elevated" padding="10px">
                            <Stack spacing={4}>
                                <HStack>
                                    <InputGroup>
                                        <InputLeftAddon children='Cliente' width={150} />
                                        <Input isDisabled {...register('customer', {required: 'Seleccione el cliente'})} />
                                        <CustomerSearch handleSelect={handleSelect}/>
                                    </InputGroup>

                                    <InputGroup>
                                        <InputLeftAddon children='Condición I.V.A.' width={150} />
                                        <Input isDisabled {...register('iva')} />
                                    </InputGroup>
                                </HStack>
                                {errors.customer && <AlertPop title={errors.customer.message} />}

                                <HStack>
                                    <InputGroup>
                                        <InputLeftAddon children='C.U.I.T.' width={150} />
                                        <Input isDisabled {...register('cuit')} />
                                    </InputGroup>

                                    <InputGroup>
                                        <InputLeftAddon children='Dirección' width={150} />
                                        <Input isDisabled {...register('street')} />
                                    </InputGroup>

                                    <InputGroup>
                                        <InputLeftAddon children='Ciudad' width={150} />
                                        <Input isDisabled {...register('city')} />
                                    </InputGroup>
                                </HStack>
                            </Stack>
                        </Card>
                        
                        
                            <Stack spacing={4}>
                                <InputGroup width="300px">
                                    <InputLeftAddon children='N° Presupuesto' width={150} />
                                    <Input isDisabled {...register('budgetNumber')} />
                                </InputGroup>

                                <HStack>
                                    <HStack spacing={0}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fecha' width={150} />
                                        </InputGroup>
                                        <DatePicker locale='es' selected={budgetDate} onChange={(date) => setBudgetDate(date)} dateFormat="dd/MM/yyyy" />
                                    </HStack>

                                    <InputGroup>
                                        <InputLeftAddon children='Validez (días)' width={150} />
                                        <Input type='number' {...register('validity', {required: 'Ingrese la validez del presupuesto'})} />
                                    </InputGroup>

                                    <InputGroup>
                                        <InputLeftAddon children='Forma de pago' width={150} />
                                        <Select placeholder='-Selecciona-' {...register('paymentMethod', {required: 'Seleccione la forma de pago'})}>
                                            {paymentMethods.map(paymentMethod => {
                                                return (
                                                <option key={paymentMethod.id} value={paymentMethod.id}>{paymentMethod.name}</option>
                                            )})}
                                        </Select>
                                    </InputGroup>
                                </HStack>
                                {errors.validity && <AlertPop title={errors.validity.message} />}
                                {errors.paymentMethod && <AlertPop title={errors.paymentMethod.message} />}

                                <InputGroup>
                                    <InputLeftAddon children='Observaciones' width={150} />
                                    <Textarea size='sm' {...register('observations')} />
                                </InputGroup>

                                <HStack>
                                    <InputGroup>
                                        <InputLeftAddon children='Importe bruto $' width={140} />
                                        <Input isDisabled placeholder='$' type='number' step='0.01' value={grossPrice} />
                                    </InputGroup>

                                    <InputGroup>
                                        <InputLeftAddon children='Importe I.V.A. $' width={140} />
                                        <Input isDisabled placeholder='$' type='number' step='0.01' value={ivaPrice} />
                                    </InputGroup>

                                    <InputGroup>
                                        <InputLeftAddon children='Importe $' width={150} />
                                        <Input isDisabled placeholder='$' type='number' step='0.01' value={price} />
                                    </InputGroup>
                                </HStack>
                            </Stack>
                        <Spacer/>
                        
                        <HStack>
                            <SaleProductAdd addToCart={addToCart}/>
                            <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                        </HStack>

                        <Spacer/>
                    </Stack>
                
                </form>
                
                <TableContainer paddingTop="15px">
                    <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                        <Thead>
                            {
                                // Recorremos las columnas que previamente definimos
                                headerGroups.map(headerGroup => (
                                    // Añadimos las propiedades al conjunto de columnas
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        // Recorremos cada columna del conjunto para acceder a su información
                                        headerGroup.headers.map((column) => (
                                        // Añadimos las propiedades a cada celda de la cabecera
                                        <Th {...column.getHeaderProps()} >
                                            {
                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                            column.render("Header")
                                            }
                                        </Th>
                                        ))
                                    }
                                    </Tr>
                                ))
                            }
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {
                                // Recorremos las filas
                                page.map(row => {
                                    // Llamamos a la función que prepara la fila previo renderizado
                                    prepareRow(row);
                                    return (
                                    // Añadimos las propiedades a la fila
                                    <Tr {...row.getRowProps()}>
                                        {
                                        // Recorremos cada celda de la fila
                                        row.cells.map((cell) => {
                                            // Añadimos las propiedades a cada celda de la fila
                                            return (
                                            <Td {...cell.getCellProps()}>
                                                {
                                                // Pintamos el contenido de la celda
                                                cell.render("Cell")
                                                }
                                            </Td>
                                            );
                                        })
                                        }
                                        <Td>
                                            <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={() => removeFromCart(row.values)}><DeleteIcon/></Button></Tooltip>
                                        </Td>
                                    </Tr>
                                    );
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>

                <Box paddingTop="10px" textAlign="center">
                    <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                    <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                </Box>

                <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Confirmar presupuesto
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Desea confirmar el presupuesto?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            No
                        </Button>
                        <Button colorScheme='blue' onClick={confirmBudget} ml={3}>
                            Confirmar
                        </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>

            </Box>
        </>
    )
}

export default BudgetAdd