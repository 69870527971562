import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
  page: {
    size: "A4"
  },
  container: {
    padding: "25px"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    height: "100px"
  },
  headerLeft: {
    flex: 1,
    textAlign: "left",
    fontSize: "10"
  },
  TitleHeaderLeft: {
    textAlign: "center",
    fontSize: "20"
  },
  headerRight: {
    flex: 1,
    textAlign: "center",
    fontSize: "15"
  },
  headerRightPrincipal: {
    height: "90px"
  },
  headerLetter: {
    width: "40px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "black"
  },
  letter: {
    height: "30px",
    borderStyle: "solid",
    borderWidth: "1",
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
    fontSize: "25"
  },
  clientContainer: {
    display: "flex",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    height: "70px",
    marginTop: "2px"
  },
  clientLeft: {
    flex: 2,
    fontSize: "12"
  },
  clientRight: {
    flex: 1,
    fontSize: "12"
  },
  productsContainer: {
    display: "flex",
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    height: "450px",
    marginTop: "2px"
  },
  titleProductsContainer: {
    height: "15px",
    backgroundColor: "black",
    color: "white",
    fontSize: "8",
    fontWeight: "bold",
    flexDirection: "row"
  },
  codigTitle: {
    width: "45px",
    textAlign: "center",
    paddingTop: "3px"
  },
  detailTitle: {
    width: "450px",
    textAlign: "center",
    paddingTop: "3px"
  },
  quantityTitle: {
    width: "50px",
    textAlign: "center",
    paddingTop: "3px"
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2px",
    height: "150px"
  },
  leftFooter: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  observations: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    fontSize: "10",
    marginTop: "2px",
    flexDirection: "column",
    display: "flex"
  },
  titleObservations: {
    height: "15px",
    width: "100%",
    backgroundColor: "black",
    color: "white",
    fontSize: "8",
    fontWeight: "bold"
  },
  detailObservations: {
    fontSize: "8",
    width: "100%"
  },
  rightFooter: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    textAlign: "center",
    fontSize: "14",
    paddingTop: "110px",
    width: "100%"
  },
  products: {
    fontSize: "10",
    flexDirection: "row"
  },
  productCodig: {
    width: "45px",
    textAlign: "center",
    paddingTop: "3px"
  },
  productDetail: {
    width: "450px",
    textAlign: "left",
    paddingTop: "3px"
  },
  productQuantity: {
    width: "50px",
    textAlign: "center",
    paddingTop: "3px"
  },
  enter: {
    color: "white"
  }
});

// Create Document Component
const PDFDespatch = ({ customerName, customerStreet, customerIva, customerCity, customerCuit, despatchNumber,
                        date, products, observations, invoiceNumber, customerState }) => (
  
  <Document>
    <Page style={styles.page}>
        <View style={styles.container}>
            <View style={styles.headerContainer}>
              <View style={styles.headerLeft}>
                <View style={styles.TitleHeaderLeft}>
                  <Text>CORRALÓN BIANCHI</Text>
                </View>
                <View style={styles.enter}><Text>Enter</Text></View>
                <Text>Razón social: Bianchi Juan José</Text>
                <Text>Domicilio comercial: Urquiza 225 - Ticino, Córdoba</Text>
                <Text>Cond. frante al IVA: IVA RESPONSABLE INSCRIPTO</Text>
                <Text>Celular: 3534275396</Text>
              </View>
              <View style={styles.headerLetter}>
                <View style={styles.letter}><Text>X</Text></View>
              </View>
              <View style={styles.headerRight}>
                <View style={styles.headerRightPrincipal}>
                  <Text>Remito</Text>
                  <Text>N°: {despatchNumber}</Text>
                  <Text>Fecha: {date}</Text>
                </View>
                <Text style={{fontSize: "7"}}>Documento no válido como factura</Text>
              </View>
            </View>
            <View style={styles.clientContainer}>
              <View style={styles.clientLeft}>
                <Text>C.U.I.T.: {customerCuit}</Text>
                <Text>Apellido y Nombre/Razón Social: {customerName}</Text>
                <Text>Domicilio: {customerStreet} - {customerCity}, {customerState}</Text>
                <Text>Cond. I.V.A.: {customerIva}</Text>
              </View>
              <View style={styles.clientRight}>
                <Text>Comprobante/Factura: {invoiceNumber}</Text>
              </View>
            </View>
            <View style={styles.productsContainer}>
              <View style={styles.titleProductsContainer}>
                <View style={styles.codigTitle}>
                  <Text>CÓDIGO</Text>
                </View>
                <View style={styles.detailTitle}>
                  <Text>DETALLE</Text>
                </View>
                <View style={styles.quantityTitle}>
                  <Text>CANTIDAD</Text>
                </View>
              </View>
              <View style={styles.products}>
                <View style={styles.productCodig}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.id}</Text>
                  ))}
                </View>
                <View style={styles.productDetail}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.name}</Text>
                  ))}
                </View>
                <View style={styles.productQuantity}>
                  {products && products.map(product => (
                    <Text key={product._id}>{parseFloat(product.quantity).toFixed(2)}</Text>
                  ))}
                </View>
              </View>
            </View>
            <View style={styles.footerContainer}>
              <View style={styles.leftFooter}>
                <View style={styles.observations}>
                  <View style={styles.titleObservations}>
                    <Text style={{paddingTop: "3px", paddingLeft: "3px"}}>OBSERVACIONES</Text>
                  </View>
                  <View style={styles.detailObservations}>
                    <Text>{observations}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.rightFooter}>
                <Text>Recibí conforme</Text>
              </View>
            </View>
        </View>
    </Page>
  </Document>
);

export default PDFDespatch