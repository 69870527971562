import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
const moment = require('moment')
moment.locale("es")

// Create styles
const styles = StyleSheet.create({
  page: {
    size: "A4"
  },
  container: {
    padding: "25px"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    height: "100px"
  },
  headerLeft: {
    flex: 1,
    textAlign: "left",
    fontSize: "10"
  },
  TitleHeaderLeft: {
    textAlign: "center",
    fontSize: "20"
  },
  headerRight: {
    flex: 1,
    textAlign: "center",
    fontSize: "15"
  },
  headerRightPrincipal: {
    height: "90px"
  },
  headerLetter: {
    width: "40px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "black"
  },
  letter: {
    height: "30px",
    borderStyle: "solid",
    borderWidth: "1",
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
    fontSize: "25"
  },
  clientContainer: {
    display: "flex",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    height: "70px",
    marginTop: "2px"
  },
  clientLeft: {
    flex: 1,
    fontSize: "12"
  },
  clientRight: {
    flex: 1,
    fontSize: "12"
  },
  productsContainer: {
    display: "flex",
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    height: "600px",
    marginTop: "2px"
  },
  titleProductsContainer: {
    height: "15px",
    backgroundColor: "black",
    color: "white",
    fontSize: "8",
    fontWeight: "bold",
    flexDirection: "row"
  },
  codigTitle: {
    width: "40px",
    textAlign: "center",
    paddingTop: "3px"
  },
  detailTitle: {
    width: "200px",
    textAlign: "center",
    paddingTop: "3px"
  },
  quantityTitle: {
    width: "42px",
    textAlign: "center",
    paddingTop: "3px"
  },
  dateTitle: {
    width: "70px",
    textAlign: "center",
    paddingTop: "3px"
  },
  invoiceLetterTitle: {
    width: "70px",
    textAlign: "center",
    paddingTop: "3px"
  },
  invoiceNumberTitle: {
    width: "70px",
    textAlign: "center",
    paddingTop: "3px"
  },
  products: {
    fontSize: "10",
    flexDirection: "row"
  },
  productCodig: {
    width: "40px",
    textAlign: "center",
    paddingTop: "3px"
  },
  productDetail: {
    width: "200px",
    textAlign: "left",
    paddingTop: "3px"
  },
  productQuantity: {
    width: "42px",
    textAlign: "center",
    paddingTop: "3px"
  },
  productDate: {
    width: "70px",
    textAlign: "center",
    paddingTop: "3px"
  },
  productInvoiceLetter: {
    width: "70px",
    textAlign: "center",
    paddingTop: "3px"
  },
  productInvoiceNumber: {
    width: "70px",
    textAlign: "center",
    paddingTop: "3px"
  },
  enter: {
    color: "white"
  }
});

// Create Document Component
const PDFCustomerProductsCurrentAccount = ({ customerName, customerStreet, customerIva, customerCity, customerCuit,
  products, customerState }) => (
  
  <Document>
    <Page style={styles.page}>
        <View style={styles.container}>
            <View style={styles.headerContainer}>
              <View style={styles.headerLeft}>
                <View style={styles.TitleHeaderLeft}>
                  <Text>CORRALÓN BIANCHI</Text>
                </View>
                <View style={styles.enter}><Text>Enter</Text></View>
                <Text>Razón social: Bianchi Juan José</Text>
                <Text>Domicilio comercial: Urquiza 225 - Ticino, Córdoba</Text>
                <Text>Cond. frante al IVA: IVA RESPONSABLE INSCRIPTO</Text>
                <Text>Celular: 3534275396</Text>
              </View>
              <View style={styles.headerLetter}>
                <View style={styles.letter}><Text></Text></View>
              </View>
              <View style={styles.headerRight}>
                <View style={styles.headerRightPrincipal}>
                  <Text>Productos sin entregar</Text>
                </View>
              </View>
            </View>
            <View style={styles.clientContainer}>
              <View style={styles.clientLeft}>
                <Text>C.U.I.T.: {customerCuit}</Text>
                <Text>Apellido y Nombre/Razón Social: {customerName}</Text>
                <Text>Domicilio: {customerStreet} - {customerCity}, {customerState}</Text>
                <Text>Cond. I.V.A.: {customerIva}</Text>
              </View>
            </View>
            <View style={styles.productsContainer}>
              <View style={styles.titleProductsContainer}>
                <View style={styles.codigTitle}>
                  <Text>CÓDIGO</Text>
                </View>
                <View style={styles.detailTitle}>
                  <Text>DETALLE</Text>
                </View>
                <View style={styles.quantityTitle}>
                  <Text>CANTIDAD</Text>
                </View>
                <View style={styles.dateTitle}>
                  <Text>FECHA</Text>
                </View>
                <View style={styles.invoiceLetterTitle}>
                  <Text>TIPO COMP.</Text>
                </View>
                <View style={styles.invoiceNumberTitle}>
                  <Text>N° COMP.</Text>
                </View>
              </View>
              <View style={styles.products}>
                <View style={styles.productCodig}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.id}</Text>
                  ))}
                </View>
                <View style={styles.productDetail}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.name}</Text>
                  ))}
                </View>
                <View style={styles.productQuantity}>
                  {products && products.map(product => (
                    <Text key={product._id}>{parseFloat(product.quantity).toFixed(2)}</Text>
                  ))}
                </View>
                <View style={styles.productDate}>
                  {products && products.map(product => (
                    <Text key={product._id}>{moment(product.date).format("L")}</Text>
                  ))}
                </View>
                <View style={styles.productInvoiceLetter}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.invoiceLetter}</Text>
                  ))}
                </View>
                <View style={styles.productInvoiceNumber}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.invoiceNumber}</Text>
                  ))}
                </View>
              </View>
            </View>
            {/*<View style={styles.footerContainer}>
              <View style={styles.leftFooter}>
                <View style={styles.observations}>
                  <View style={styles.titleObservations}>
                    <Text style={{paddingTop: "3px", paddingLeft: "3px"}}>OBSERVACIONES</Text>
                  </View>
                  <Text>Validez: {validity} días</Text>
                  <Text>Forma de pago: {paymentMethod}</Text>
                  <Text style={{color: "white"}}>Es un espacio en blanco</Text>
                  <Text>{observations}</Text>
                </View>
              </View>
              <View style={styles.rightFooter}>
              <View style={styles.titlesRightFooter}>
                  <Text>IMPORTE BRUTO: </Text>
                  <Text>IMPORTE IVA: </Text>
                  <Text>IMPORTE TOTAL: </Text>
                </View>
                <View style={styles.detailsRightFooter}>
                  <Text>${parseFloat(grossPrice).toFixed(2)}</Text>
                  <Text>${parseFloat(ivaPrice).toFixed(2)}</Text>
                  <Text>${parseFloat(price).toFixed(2)}</Text>
                </View>
              </View>
                  </View>*/}
        </View>
    </Page>
  </Document>
);

export default PDFCustomerProductsCurrentAccount