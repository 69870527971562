import { Box, Stack, HStack, InputGroup, InputLeftAddon, Select, AlertDialog, AlertDialogBody, 
    AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useDisclosure,
    useToast, Input, Spacer, Button, ButtonGroup, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
    ModalBody, Textarea, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Tooltip} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { PDFViewer } from '@react-pdf/renderer'
import { TYPES } from '../actions/cartAction'
import { useTable, usePagination, useSortBy } from "react-table"
import { useState, useRef, useReducer } from 'react'
import { receiptInitialState, receiptReducer } from '../reducers/receiptReducer'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import CustomerSearch from './CustomerSearch';
import PDFReceipt from './PDFReceipt'
import useColumnsReceipt from "../hooks/useColumnsReceipt"
import { DeleteIcon } from '@chakra-ui/icons'
import { BsCheckCircle } from "react-icons/bs"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')

const ReceiptAdd = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenConfirmReceipt, onOpen: onOpenConfirmReceipt, onClose: onCloseConfirmReceipt } = useDisclosure()
    const { isOpen: isOpenReceipt, onOpen: onOpenReceipt, onClose: onCloseReceipt } = useDisclosure()
    const initialRef = useRef()
    const cancelRefConfirmReceipt = useRef()
    const [date, setDate] = useState(new Date())
    const [paymentMethods, setPaymentMethods] = useState([])
    const [idCustomer, setIdCustomer] = useState(0)
    const [customer, setCustomer] = useState({})
    const [paymentMethod, setPaymentMethod] = useState("")
    const [amount, setAmount] = useState(0)
    const [receiptNumber, setReceiptNumber] = useState("")
    const [observations, setObservations] = useState("")
    const [stateReducer, dispatch] = useReducer(receiptReducer, receiptInitialState)
    const { data } = stateReducer
    const toast = useToast()
    let [ form, setForm ] = useState({})

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")

        fetch("https://api.sistemacorralonbianchi.com.ar/api/receipt/get/receipt/number", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const receipt = data.map(receipt => {
                return { 
                    receiptNumber: receipt.receiptNumber
                }
            })
            setReceiptNumber(receipt[0].receiptNumber)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        fetch("https://api.sistemacorralonbianchi.com.ar/api/paymentMethod", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrPaymentMethods = data.map(paymentMethod => {
                return { 
                    id: paymentMethod._id,
                    name: paymentMethod.name
                }
            })
            setPaymentMethods(arrPaymentMethods)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        setIdCustomer(0)
        setCustomer({})
        setPaymentMethod("")
        setDate(new Date())
        setObservations("")
        clearReceipt()
        onOpen()
    }

    const columns = useColumnsReceipt();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["id"], sortBy: [{id: "date", asc: true}]} }, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state
    } = table

    const handleSelect = (idNewCustomer) => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.sistemacorralonbianchi.com.ar/api/customer/" + idNewCustomer, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setIdCustomer(idNewCustomer)
            setCustomer(data)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const savePayment = () => {
        if (paymentMethod === ""){
            toast({
                title: "Seleccione una forma de pago",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (amount < 1){
            toast({
                title: "Ingrese un importe",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        addPayment()
    }

    const addPayment = () => {
        const payment = {
            paymentMethod: paymentMethod,
            amount: parseFloat(amount).toFixed(2)
        }

        dispatch({ type:TYPES.ADD_TO_CART, payload: payment })
        setAmount(0)
    }

    const clearReceipt = () => {
        dispatch({ type:TYPES.CLEAR_CART })
    }

    const removeFromReceipt = (payment) => {
        dispatch({ type:TYPES.REMOVE_ONE_FROM_CART, payload: payment })
    }

    const handleSubmit = () => {
        if (customer.name === "" || customer.name === undefined){
            toast({
                title: "Seleccione el cliente",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (date === null){
            toast({
                title: "Seleccione una fecha",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (data.length < 1){
            toast({
                title: "Ingrese al menos un pago",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        onOpenConfirmReceipt()
    }

    const confirmReceipt = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("en")

        const payments = []
        let sumAmount = 0

        for (let i=0; i < data.length; i++) {
            payments.push({
                paymentMethod: data[i].paymentMethod,
                amount: parseFloat(data[i].amount)
            })
            sumAmount = sumAmount + parseFloat(data[i].amount)
        }

        const receipt = {
            customer: idCustomer,
            receiptNumber: receiptNumber,
            date: moment(date).format('L'),
            payments: payments,
            amount: parseFloat(sumAmount),
            observations: observations
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/receipt", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(receipt)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        openReceipt()
    }

    const openReceipt = () => {
        moment.locale("es")

        const accessToken = localStorage.getItem("user")

        const fromDate = new Date()
        const toDate = new Date()

        const newFromDate = moment(fromDate).format('L')
        const newToDate = moment(toDate).format('L')

        const filter = {
            customer: idCustomer,
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-"),
            completeAccount: true,
            unpaid: false//unpaid
        }

        const payments = []
        let sumAmount = 0

        for (let i=0; i < data.length; i++) {
            payments.push({
                paymentIndex: i,
                paymentMethod: data[i].paymentMethod,
                amount: parseFloat(data[i].amount)
            })
            sumAmount = sumAmount + parseFloat(data[i].amount)
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/customer/" + filter.customer + "/fromDate/" + 
        filter.fromDate + "/toDate/" + filter.toDate + "/completeAccount/" + filter.completeAccount + 
        "/unpaid/" + filter.unpaid, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setForm(form = {
                customerName: customer.name,
                customerStreet: customer.street,
                customerIva: customer.ivaName,
                customerCity: customer.city,
                customerState: customer.stateName,
                customerCuit: customer.cuit,
                receiptNumber: receiptNumber,
                date: moment(date).add(3,"h").format("L"),
                payments: payments,
                debt: parseFloat(data.balance) + parseFloat(sumAmount),
                paid: parseFloat(sumAmount),
                balance: parseFloat(data.balance)
            })
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onCloseConfirmReceipt()
        onOpenReceipt()

        toast({
            title: "El recibo se generó correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        onClose()
    }
    
    return(
        <>
            <ButtonGroup size='sm' isAttached>
                <Button colorScheme="yellow" onClick={handleOpen}>Recibo</Button>
                <IconButton onClick={handleOpen} aria-label='Add to friends' icon={<AddIcon />} colorScheme="yellow" variant="outline"/>
            </ButtonGroup>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nuevo recibo</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack spacing={4}>
                            <InputGroup>
                                <InputLeftAddon children='N° Recibo' width={140} />
                                <Input isDisabled value={receiptNumber}/>
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Cliente' width={140} />
                                <Input isDisabled value={customer.name}/>
                                <CustomerSearch handleSelect={handleSelect}/>
                            </InputGroup>

                            <HStack spacing={0}>
                                <InputGroup flex={1}>
                                    <InputLeftAddon children='Fecha' width={140} />
                                </InputGroup>
                                <DatePicker flex={2} locale='es' selected={date} onChange={(date) => setDate(date)} dateFormat="dd/MM/yyyy" />
                            </HStack>

                            <InputGroup>
                                <InputLeftAddon children='Formas de pago' width={140} />
                                <Select placeholder='-Selecciona-' onChange={(e) => setPaymentMethod(e.target.value)}>
                                {paymentMethods.map(paymentMethod => {
                                    return (
                                    <option key={paymentMethod.id} value={paymentMethod.name}>{paymentMethod.name}</option>
                                )})}
                                </Select>
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Importe $' width={140} />
                                <Input placeholder='$' type='number' step='0.01' value={amount} onChange={(e) => setAmount(e.target.value)}/>
                            </InputGroup>

                            <Box textAlign="center">
                                <Tooltip label='Ingresar pago'><Button colorScheme="whatsapp" onClick={savePayment}><BsCheckCircle/></Button></Tooltip>
                            </Box>

                            <TableContainer paddingTop="15px">
                                <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                    <Thead>
                                        {
                                            // Recorremos las columnas que previamente definimos
                                            headerGroups.map(headerGroup => (
                                                // Añadimos las propiedades al conjunto de columnas
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    // Recorremos cada columna del conjunto para acceder a su información
                                                    headerGroup.headers.map((column) => (
                                                    // Añadimos las propiedades a cada celda de la cabecera
                                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                        {
                                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                                        column.render("Header")
                                                        }
                                                    </Th>
                                                    ))
                                                }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            // Recorremos las filas
                                            page.map(row => {
                                                // Llamamos a la función que prepara la fila previo renderizado
                                                prepareRow(row);
                                                return (
                                                // Añadimos las propiedades a la fila
                                                <Tr {...row.getRowProps()}>
                                                    {
                                                    // Recorremos cada celda de la fila
                                                    row.cells.map((cell) => {
                                                        // Añadimos las propiedades a cada celda de la fila
                                                        return (
                                                        <Td {...cell.getCellProps()}>
                                                            {
                                                            // Pintamos el contenido de la celda
                                                            cell.render("Cell")
                                                            }
                                                        </Td>
                                                        );
                                                    })
                                                    }
                                                    <Td>
                                                        <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={(e) => removeFromReceipt(row.values.paymentMethod)}><DeleteIcon/></Button></Tooltip>
                                                    </Td>
                                                </Tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>

                            <InputGroup>
                                <InputLeftAddon children='Observaciones' width={140} />
                                <Textarea size='sm' onChange={(e) => setObservations(e.target.value)} />
                            </InputGroup>

                            <Box textAlign="center">
                                <Button colorScheme="blue" onClick={handleSubmit} width={140}>Generar recibo</Button>
                            </Box>

                            <Spacer/>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <AlertDialog
                isOpen={isOpenConfirmReceipt}
                leastDestructiveRef={cancelRefConfirmReceipt}
                onClose={onCloseConfirmReceipt}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirmar recibo
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        ¿Desea confirmar el recibo?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRefConfirmReceipt} onClick={onCloseConfirmReceipt}>
                        No
                    </Button>
                    <Button colorScheme='blue' onClick={confirmReceipt} ml={3}>
                        Confirmar
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <Modal
                isOpen={isOpenReceipt}
                onClose={onCloseReceipt}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Recibo</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFReceipt {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>
            
        </>
    )
}

export default ReceiptAdd