import { Button, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
    useDisclosure, Stack, CircularProgress, Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Box, Divider } from "@chakra-ui/react"
import { Search2Icon } from '@chakra-ui/icons'
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table"
import { GlobalFilter } from './GlobalFilter'
import { useRef, useState } from 'react'
import useColumnsCustomers from "../hooks/useColumnsCustomers"
import CustomerInvoices from "./CustomerInvoices"
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const CustomerInvoiceSearch = ({ handleSelect }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)

        fetch("https://api.sistemacorralonbianchi.com.ar/api/customer", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCustomers = data.map(customer => {
                return {
                    _id: customer._id,
                    name: customer.name,
                    iva: customer.iva,
                    cuit: customer.cuit,
                    celPhone: customer.celPhone
                }
            })
            setData(arrCustomers)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    const columns = useColumnsCustomers();
    const table = useTable({ columns, data, initialState: {pageSize: 10, pageIndex: 0, hiddenColumns: ["_id", "celPhone"], sortBy: [{id: "name", asc: true}]} }, useGlobalFilter, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter
      } = table;

      const { globalFilter } = state

    const handleSelectCustomer = (idCustomer, idInvoice, invoiceNumber) => {
        handleSelect(idCustomer, idInvoice, invoiceNumber)

        onClose()
    }
    
    return (
        <>
            <Tooltip label='Buscar cliente'><Button colorScheme="blackAlpha" onClick={handleOpen}><Search2Icon/></Button></Tooltip>
        
            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='4xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Seleccionar cliente</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>

                        <Box paddingTop="10px">
                            <GlobalFilter filter={ globalFilter } setFilter={ setGlobalFilter } />
                        </Box>

                        <Divider paddingTop="15px"/>

                        {loading && <Box paddingTop="15px" textAlign='center'> 
                            <CircularProgress isIndeterminate color='green.300' />
                        </Box>}

                        {!loading && <TableContainer paddingTop="15px">
                            <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                <Thead>
                                    {
                                        // Recorremos las columnas que previamente definimos
                                        headerGroups.map(headerGroup => (
                                            // Añadimos las propiedades al conjunto de columnas
                                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                // Recorremos cada columna del conjunto para acceder a su información
                                                headerGroup.headers.map((column) => (
                                                // Añadimos las propiedades a cada celda de la cabecera
                                                <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                    {
                                                    // Pintamos el título de nuestra columna (propiedad "Header")
                                                    column.render("Header")
                                                    }
                                                </Th>
                                                ))
                                            }
                                            </Tr>
                                        ))
                                    }
                                </Thead>
                                <Tbody {...getTableBodyProps()}>
                                    {
                                        // Recorremos las filas
                                        page.map(row => {
                                            // Llamamos a la función que prepara la fila previo renderizado
                                            prepareRow(row);
                                            return (
                                            // Añadimos las propiedades a la fila
                                            <Tr {...row.getRowProps()}>
                                                {
                                                // Recorremos cada celda de la fila
                                                row.cells.map((cell) => {
                                                    // Añadimos las propiedades a cada celda de la fila
                                                    return (
                                                    <Td {...cell.getCellProps()}>
                                                        {
                                                        // Pintamos el contenido de la celda
                                                        cell.render("Cell")
                                                        }
                                                    </Td>
                                                    );
                                                })
                                                }
                                                <Td>
                                                    <CustomerInvoices idCustomer={row.values._id} handleSelectCustomer={handleSelectCustomer}/>
                                                </Td>
                                            </Tr>
                                            );
                                        })
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>}

                        {!loading && <Box paddingTop="10px" textAlign="center">
                            <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                            <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                        </Box>}

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CustomerInvoiceSearch