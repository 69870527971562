const login = (username, password) => {
    const user = {
        username: username,
        password: password
    }

    //https://commercial-management-386819.rj.r.appspot.com o http://localhost:3001 o https://api.sistemacorralonbianchi.com.ar
    return fetch("https://api.sistemacorralonbianchi.com.ar/api/user/login", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
    .then(response => response.json())
    .then(data => {
        if (data.accessToken) {
            localStorage.setItem("user", JSON.stringify(data.accessToken));
        }

        return data.accessToken
    })
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const authService = {
  login,
  logout,
  getCurrentUser,
};

export default authService;