import { Button, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
    Stack, CircularProgress, Table, Thead, Tbody, Tr, Th, Td, TableContainer, useDisclosure,
    Box, FormLabel, HStack, Card, FormControl, Switch, Divider, Flex } from "@chakra-ui/react"
import { useTable, useSortBy, usePagination } from "react-table"
import { BsClipboard } from "react-icons/bs"
import { useRef, useState } from 'react'
import useColumnsCustomerCurrentAccount from "../hooks/useColumnsCustomerCurrentAccount"
//import Sale from "./Sale"
//import SalePayments from "./SalePayments"
import PrintPDFVoucher from "./PrintPDFVoucher"
import InvoiceAdd from "./InvoiceAdd"
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')

const CustomerCurrentAccount = ({ idCustomer, nameCustomer, cuit }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const [fromDate, setFromDate] = useState(new Date(moment().subtract(1, 'month')))
    const [toDate, setToDate] = useState(new Date())
    const [completeAccount, setCompleteAccount] = useState(true)
    //const [unpaid, setUnpaid] = useState(false)
    const [totalSale, setTotalSale] = useState("")
    const [totalPaid, setTotalPaid] = useState("")
    const [balance, setBalance] = useState("")
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")
        setLoading(true)
        setCompleteAccount(true)

        const dateFromDate = new Date(moment().subtract(1, 'month'))
        const dateToDate = new Date()

        setFromDate(new Date(moment().subtract(1, 'month')))
        setToDate(new Date())

        const newFromDate = moment(dateFromDate).format('L')
        const newToDate = moment(dateToDate).format('L')

        const filter = {
            customer: idCustomer,
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-"),
            completeAccount: true,
            unpaid: false//unpaid
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/customer/" + filter.customer + "/fromDate/" + 
        filter.fromDate + "/toDate/" + filter.toDate + "/completeAccount/" + filter.completeAccount + 
        "/unpaid/" + filter.unpaid, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrVouchers = data.arrVouchers.map(voucher => {
                return {
                    id: voucher._id,
                    date: voucher.date,
                    formatDate: moment(voucher.date).add(3,"h").format('L'),
                    voucherNumber: voucher.voucherNumber,
                    voucherLetter: voucher.voucherLetter,
                    price: parseFloat(voucher.price).toFixed(2),
                    isInvoice: voucher.isInvoice
                }
            })

            setTotalSale(parseFloat(data.sales).toFixed(2))
            setTotalPaid(parseFloat(data.paid).toFixed(2))
            setBalance(parseFloat(data.balance).toFixed(2))
            setData(arrVouchers)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        onOpen()

    }

    const columns = useColumnsCustomerCurrentAccount();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["id", "date", "isInvoice"], sortBy: [{id: "date", desc: true}]} }, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state
    } = table

    const handleFromDate = (date) => {
        const accessToken = localStorage.getItem("user")

        moment.locale('es')

        setFromDate(date || new Date())

        setLoading(true)

        const newFromDate = moment(date).format('L')
        const newToDate = moment(toDate).format('L')

        const filter = {
            customer: idCustomer,
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-"),
            completeAccount: completeAccount,
            unpaid: false//unpaid
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/customer/" + filter.customer + "/fromDate/" + 
        filter.fromDate + "/toDate/" + filter.toDate + "/completeAccount/" + filter.completeAccount + 
        "/unpaid/" + filter.unpaid, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrVouchers = data.arrVouchers.map(voucher => {
                return {
                    id: voucher._id,
                    date: voucher.date,
                    formatDate: moment(voucher.date).add(3,"h").format('L'),
                    voucherNumber: voucher.voucherNumber,
                    voucherLetter: voucher.voucherLetter,
                    price: parseFloat(voucher.price).toFixed(2),
                    isInvoice: voucher.isInvoice + ""
                }
            })

            setTotalSale(parseFloat(data.sales).toFixed(2))
            setTotalPaid(parseFloat(data.paid).toFixed(2))
            setBalance(parseFloat(data.balance).toFixed(2))
            setData(arrVouchers)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const handleToDate = (date) => {
        const accessToken = localStorage.getItem("user")

        moment.locale('es')

        setToDate(date || new Date())

        setLoading(true)

        const newFromDate = moment(fromDate).format('L')
        const newToDate = moment(date).format('L')

        const filter = {
            customer: idCustomer,
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-"),
            completeAccount: completeAccount,
            unpaid: false//unpaid
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/customer/" + filter.customer + "/fromDate/" + 
        filter.fromDate + "/toDate/" + filter.toDate + "/completeAccount/" + filter.completeAccount + 
        "/unpaid/" + filter.unpaid, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrVouchers = data.arrVouchers.map(voucher => {
                return {
                    id: voucher._id,
                    date: voucher.date,
                    formatDate: moment(voucher.date).add(3,"h").format('L'),
                    voucherNumber: voucher.voucherNumber,
                    voucherLetter: voucher.voucherLetter,
                    price: parseFloat(voucher.price).toFixed(2),
                    isInvoice: voucher.isInvoice + ""
                }
            })

            setTotalSale(parseFloat(data.sales).toFixed(2))
            setTotalPaid(parseFloat(data.paid).toFixed(2))
            setBalance(parseFloat(data.balance).toFixed(2))
            setData(arrVouchers)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const handleCompleteAccount = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale('es')

        setLoading(true)

        const newFromDate = moment(fromDate).format('L')
        const newToDate = moment(toDate).format('L')

        setCompleteAccount(!completeAccount)

        const filter = {
            customer: idCustomer,
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-"),
            completeAccount: !completeAccount,
            unpaid: false//unpaid
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/customer/" + filter.customer + "/fromDate/" + 
        filter.fromDate + "/toDate/" + filter.toDate + "/completeAccount/" + filter.completeAccount + 
        "/unpaid/" + filter.unpaid, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrVouchers = data.arrVouchers.map(voucher => {
                return {
                    id: voucher._id,
                    date: voucher.date,
                    formatDate: moment(voucher.date).add(3,"h").format('L'),
                    voucherNumber: voucher.voucherNumber,
                    voucherLetter: voucher.voucherLetter,
                    price: parseFloat(voucher.price).toFixed(2),
                    isInvoice: voucher.isInvoice + ""
                }
            })

            setTotalSale(parseFloat(data.sales).toFixed(2))
            setTotalPaid(parseFloat(data.paid).toFixed(2))
            setBalance(parseFloat(data.balance).toFixed(2))
            setData(arrVouchers)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    /*const handleUnpaid = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale('es')

        setLoading(true)

        const newFromDate = moment(fromDate).format('L')
        const newToDate = moment(toDate).format('L')

        setUnpaid(!unpaid)

        const filter = {
            customer: idCustomer,
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-"),
            completeAccount: completeAccount,
            unpaid: !unpaid
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/customer/" + filter.customer + "/fromDate/" + 
        filter.fromDate + "/toDate/" + filter.toDate + "/completeAccount/" + filter.completeAccount + 
        "/unpaid/" + filter.unpaid, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrSales = data.map(sale => {
                return {
                    id: sale._id,
                    date: sale.date,
                    formatDate: moment(sale.date).format('L'),
                    invoiceNumber: sale.invoiceNumber,
                    invoiceLetter: sale.invoiceLetter,
                    price: parseFloat(sale.price).toFixed(2),
                    totalPaid: parseFloat(sale.totalPaid).toFixed(2),
                    balance: parseFloat(sale.balance).toFixed(2)
                }
            })
            let sumPrice = 0
            let sumTotalPaid = 0
            let sumBalance = 0

            for (let i=0; i < arrSales.length; i++) {
                sumPrice = parseFloat(sumPrice) + parseFloat(arrSales[i].price)
                sumTotalPaid = parseFloat(sumTotalPaid) + parseFloat(arrSales[i].totalPaid)
                sumBalance = parseFloat(sumBalance) + parseFloat(arrSales[i].balance)
            }

            setTotalSale(parseFloat(sumPrice).toFixed(2))
            setTotalPaid(parseFloat(sumTotalPaid).toFixed(2))
            setBalance(parseFloat(sumBalance).toFixed(2))
            setData(arrSales)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }*/

    const handleSubmitModal = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")
        setLoading(true)

        const dateFromDate = new Date(moment().subtract(1, 'month'))
        const dateToDate = new Date()

        setFromDate(new Date(moment().subtract(1, 'month')))
        setToDate(new Date())

        const newFromDate = moment(dateFromDate).format('L')
        const newToDate = moment(dateToDate).format('L')

        const filter = {
            customer: idCustomer,
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-"),
            completeAccount: completeAccount,
            unpaid: false//unpaid
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/customer/" + filter.customer + "/fromDate/" + 
        filter.fromDate + "/toDate/" + filter.toDate + "/completeAccount/" + filter.completeAccount + 
        "/unpaid/" + filter.unpaid, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrVouchers = data.arrVouchers.map(voucher => {
                return {
                    id: voucher._id,
                    date: voucher.date,
                    formatDate: moment(voucher.date).add(3,"h").format('L'),
                    voucherNumber: voucher.voucherNumber,
                    voucherLetter: voucher.voucherLetter,
                    price: parseFloat(voucher.price).toFixed(2),
                    isInvoice: voucher.isInvoice + ""
                }
            })

            setTotalSale(parseFloat(data.sales).toFixed(2))
            setTotalPaid(parseFloat(data.paid).toFixed(2))
            setBalance(parseFloat(data.balance).toFixed(2))
            setData(arrVouchers)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    return (
        <>
            <Tooltip label='Ver cuenta'><Button size="sm" colorScheme="green" onClick={handleOpen}><BsClipboard/></Button></Tooltip>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='5xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Cuenta corriente</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack spacing={4}>

                            <Card align="center" variant="elevated" padding="10px">
                                <FormLabel>{nameCustomer}</FormLabel>

                                <HStack paddingTop="15px">
                                    <Box></Box>
                                    <FormLabel>Desde:</FormLabel>
                                    <DatePicker locale='es' selected={fromDate} onChange={(date) => handleFromDate(date)} dateFormat="dd/MM/yyyy" />

                                    <FormLabel>Hasta:</FormLabel>
                                    <DatePicker locale='es' selected={toDate} onChange={(date) => handleToDate(date)} dateFormat="dd/MM/yyyy" />
                                </HStack>
                            </Card>

                            <Box paddingTop="10px">
                                <HStack>

                                <FormControl display='flex' alignItems='center'>
                                    <FormLabel htmlFor='completeAccount' mb='0'>Cuenta completa</FormLabel>
                                    <Switch id='completeAccount' defaultChecked={completeAccount} onChange={(e) => handleCompleteAccount()} />
                                </FormControl>

                                {/*<FormControl display='flex' alignItems='center'>
                                    <FormLabel htmlFor='unpaid' mb='0'>Ver sólo impagas</FormLabel>
                                    <Switch id='unpaid' defaultChecked={unpaid} onChange={(e) => handleUnpaid()} />
                                </FormControl>*/}

                                </HStack>
                            </Box>

                            <Divider paddingTop="15px"/>

                            <Flex paddingTop="10px">
                                <Box>
                                    <FormLabel textAlign="right">Total de ventas: </FormLabel>
                                    <FormLabel textAlign="right">Total pagado: </FormLabel>
                                    <FormLabel textAlign="right">Saldo: </FormLabel>
                                </Box>
                                <Box>
                                    <FormLabel textAlign="left">${totalSale}</FormLabel>
                                    <FormLabel textAlign="left">${totalPaid}</FormLabel>
                                    <FormLabel textAlign="left" bg="red">${balance}</FormLabel>
                                </Box>
                            </Flex>

                            <Divider paddingTop="15px"/>

                            {loading && <Box paddingTop="15px" textAlign='center'> 
                                <CircularProgress isIndeterminate color='green.300' />
                            </Box>}

                            {!loading && <TableContainer paddingTop="15px">
                                <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                    <Thead>
                                        {
                                            // Recorremos las columnas que previamente definimos
                                            headerGroups.map(headerGroup => (
                                                // Añadimos las propiedades al conjunto de columnas
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    // Recorremos cada columna del conjunto para acceder a su información
                                                    headerGroup.headers.map((column) => (
                                                    // Añadimos las propiedades a cada celda de la cabecera
                                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                        {
                                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                                        column.render("Header")
                                                        }
                                                    </Th>
                                                    ))
                                                }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            // Recorremos las filas
                                            page.map(row => {
                                                // Llamamos a la función que prepara la fila previo renderizado
                                                prepareRow(row);
                                                return (
                                                // Añadimos las propiedades a la fila
                                                <Tr {...row.getRowProps()}>
                                                    {
                                                    // Recorremos cada celda de la fila
                                                    row.cells.map((cell) => {
                                                        // Añadimos las propiedades a cada celda de la fila
                                                        return (
                                                        <Td {...cell.getCellProps()}>
                                                            {
                                                            // Pintamos el contenido de la celda
                                                            cell.render("Cell")
                                                            }
                                                        </Td>
                                                        );
                                                    })
                                                    }
                                                    <Td>
                                                        <HStack>
                                                            {/*<Sale idSale={row.values.id} handleSubmitModal={handleSubmitModal}/>*/}
                                                            {/*<SalePayments idSale={row.values.id} price={row.values.price} handleSubmitModal={handleSubmitModal}/>*/}
                                                            <PrintPDFVoucher idVoucher={row.values.id} voucherLetter={row.values.voucherLetter}/>
                                                            <InvoiceAdd idVoucher={row.values.id} cuit={cuit} isInvoice={row.values.isInvoice} handleSubmitModal={handleSubmitModal} voucherLetter={row.values.voucherLetter}/>
                                                        </HStack>
                                                    </Td>
                                                </Tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>}

                            {!loading && <Box paddingTop="10px" textAlign="center">
                                <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                                <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                            </Box>}


                        </Stack>

                        
                    </ModalBody>
                </ModalContent>
            </Modal>

        </>
    )
}

export default CustomerCurrentAccount