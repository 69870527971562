import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
    Input, Button, ButtonGroup, IconButton, useDisclosure, InputGroup, 
    InputLeftAddon, Stack, useToast, Select, Spacer, Flex } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const CustomerAdd = ({handleSubmitModal}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const toast = useToast()
    const [idCustomer, setIdCustomer] = useState(0)
    const [iva, setIva] = useState([])
    const [states, setStates] = useState([])

    const navigate = useNavigate();

    const onSubmit = data => {
        const accessToken = localStorage.getItem("user")
        
        const customer = {
            name: data.name,
            street: data.street,
            phone: data.phone,
            celPhone: data.celPhone,
            city: data.city,
            state: data.state,
            cuit: data.cuit,
            email: data.email,
            iva: data.iva
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/customer", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(customer)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        toast({
            title: "¡Cliente añadido!",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        handleSubmitModal()
        onClose()
    }

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")
        /*fetch("https://api.sistemacorralonbianchi.com.ar/api/customer/last/id")
        .then(response => response.json())
        .then(data => {
            setIdCustomer(data)
        })
        .catch(error => {
            console.log(error)
        })*/

        fetch("https://api.sistemacorralonbianchi.com.ar/api/iva", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrIva = data.map(iva => {
                return { 
                    id: iva._id,
                    name: iva.name
                }
        })
        setIva(arrIva)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        fetch("https://api.sistemacorralonbianchi.com.ar/api/state", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrStates = data.map(state => {
                return { 
                    id: state._id,
                    name: state.name
                }
        })
        setStates(arrStates)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        reset()
        onOpen()
    }

    const handleClose = () => {
        reset()
        onClose()
    }

    return (
        <>
            <ButtonGroup size='sm' isAttached>
                <Button colorScheme="whatsapp" onClick={handleOpen}>Nuevo cliente</Button>
                <IconButton onClick={handleOpen} aria-label='Add to friends' icon={<AddIcon />} colorScheme="whatsapp" variant="outline"/>
            </ButtonGroup>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={handleClose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nuevo cliente</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={4}>
                            {/*<InputGroup>
                                <InputLeftAddon children='ID' />
                                <Input placeholder='ID' isDisabled required value={idCustomer} />
                            </InputGroup>*/}

                            <InputGroup>
                                <InputLeftAddon children='Nombre' width={127} />
                                <Input ref={initialRef} placeholder='Nombre' {...register('name', {required: 'Por favor ingrese el nombre del cliente'})} />
                            </InputGroup>
                            {errors.name && <AlertPop title={errors.name.message} />}

                            <InputGroup>
                                <InputLeftAddon children='Cond. I.V.A.' width={127} />
                                <Select placeholder='-Selecciona-' {...register('iva', {required: 'Por favor seleccione la condición de I.V.A.'})}>
                                {iva.map(iva => {
                                    return (
                                    <option key={iva.id} value={iva.id}>{iva.name}</option>
                                )})}
                                </Select>
                            </InputGroup>
                            {errors.iva && <AlertPop title={errors.iva.message} />}

                            <InputGroup>
                                <InputLeftAddon children='Dirección' width={127} />
                                <Input placeholder='Dirección' {...register('street')} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Ciudad' width={127} />
                                <Input placeholder='Ciudad' {...register('city')} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Provincia' width={127} />
                                <Select placeholder='-Selecciona-' {...register('state')}>
                                {states.map(state => {
                                    return (
                                    <option key={state.id} value={state.id}>{state.name}</option>
                                )})}
                                </Select>
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Teléfono' width={127} />
                                <Input placeholder='Teléfono' {...register('phone')} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Celular' width={127} />
                                <Input placeholder='Celular' {...register('celPhone')} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='C.U.I.T.' width={127} />
                                <Input placeholder='Solo números' type='number' {...register('cuit', {min: 0})} />
                            </InputGroup>
                            {errors.cuit && <AlertPop title={"El número debe ser mayor a 0"} />}

                            <InputGroup>
                                <InputLeftAddon children='E-mail' width={127} />
                                <Input placeholder='E-mail' type="email" {...register('email')} />
                            </InputGroup>

                            
                        </Stack>
                        <Flex p={4}>
                        <Spacer/>
                        <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                        <Button onClick={handleClose}>Cancelar</Button>
                        </Flex>
                        
                    </form>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default CustomerAdd