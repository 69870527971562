import { Button, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
    useDisclosure, Stack, CircularProgress, Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Box, FormLabel, HStack, Card } from "@chakra-ui/react"
import { useTable, useSortBy, usePagination } from "react-table"
import { BsFileEarmarkArrowDown } from "react-icons/bs"
import { useRef, useState } from 'react'
import useColumnsDespatchs from "../hooks/useColumnsDespatchs"
//import Despatch from "./Despatch"
import PrintPDFDespatch from "./PrintPDFDespatch"
import PrintPDFProductsCurrentAccount from "./PrintPDFProductsCurrentAccount"
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')

const CustomerDespatchs = ({ idCustomer, nameCustomer }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [customerName, setCustomerName] = useState("")
    const [fromDate, setFromDate] = useState(new Date(moment().subtract(1, 'month')))
    const [toDate, setToDate] = useState(new Date())

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale('es')

        setCustomerName(nameCustomer)
        setLoading(true)

        const dateFromDate = new Date(moment().subtract(1, 'month'))
        const dateToDate = new Date()

        setFromDate(new Date(moment().subtract(1, 'month')))
        setToDate(new Date())

        const newFromDate = moment(dateFromDate).format('L')
        const newToDate = moment(dateToDate).format('L')

        const filter = {
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-")
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/despatch/customer/" + idCustomer + "/fromDate/" + filter.fromDate + "/toDate/" + filter.toDate, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrDespatchs = data.map(despatch => {
                return { 
                    id: despatch._id,
                    despatchNumber: despatch.despatchNumber,
                    invoiceNumber: despatch.invoiceNumber,
                    saleDate: moment(despatch.saleDate).add(3,"h").format('L'),
                    date: moment(despatch.date).add(3,"h").format('L'),
                    dateNoView: despatch.date
                }
            })
            setData(arrDespatchs)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    const columns = useColumnsDespatchs();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["id", "dateNoView", "customer"], sortBy: [{id: "dateNoView", desc: true}]} }, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow
    } = table

    const handleFromDate = (date) => {
        const accessToken = localStorage.getItem("user")

        moment.locale('es')

        setFromDate(date || new Date())

        setLoading(true)

        const newFromDate = moment(date).format('L')
        const newToDate = moment(toDate).format('L')

        const filter = {
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-")
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/despatch/customer/" + idCustomer + "/fromDate/" + filter.fromDate + "/toDate/" + filter.toDate, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrDespatchs = data.map(despatch => {
                return { 
                    id: despatch._id,
                    despatchNumber: despatch.despatchNumber,
                    invoiceNumber: despatch.invoiceNumber,
                    saleDate: moment(despatch.saleDate).add(3,"h").format('L'),
                    date: moment(despatch.date).add(3,"h").format('L'),
                    dateNoView: despatch.date
                }
            })
            setData(arrDespatchs)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const handleToDate = (date) => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")

        setToDate(date || new Date())

        setLoading(true)

        const newFromDate = moment(fromDate).format('L')
        const newToDate = moment(date).format('L')

        const filter = {
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-")
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/despatch/customer/" + idCustomer + "/fromDate/" + filter.fromDate + "/toDate/" + filter.toDate, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrDespatchs = data.map(despatch => {
                return { 
                    id: despatch._id,
                    despatchNumber: despatch.despatchNumber,
                    invoiceNumber: despatch.invoiceNumber,
                    saleDate: moment(despatch.saleDate).add(3,"h").format('L'),
                    date: moment(despatch.date).add(3,"h").format('L'),
                    dateNoView: despatch.date
                }
            })
            setData(arrDespatchs)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    return (
        <>
            <Tooltip label='Ver remitos'><Button size="sm" colorScheme="blackAlpha" onClick={handleOpen}><BsFileEarmarkArrowDown/></Button></Tooltip>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='4xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Detalle de remitos</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack spacing={2}>

                            <Card align="center" variant="elevated" padding="10px">
                                <FormLabel>{customerName}</FormLabel>

                                <HStack paddingTop="15px" paddingBottom="10px">
                                    <Box></Box>
                                    <FormLabel>Desde:</FormLabel>
                                    <DatePicker locale='es' selected={fromDate} onChange={(date) => handleFromDate(date)} dateFormat="dd/MM/yyyy" />

                                    <FormLabel>Hasta:</FormLabel>
                                    <DatePicker locale='es' selected={toDate} onChange={(date) => handleToDate(date)} dateFormat="dd/MM/yyyy" />
                                </HStack>

                                <PrintPDFProductsCurrentAccount idCustomer={idCustomer}/>
                            </Card>

                            {loading && <Box paddingTop="15px" textAlign='center'> 
                                <CircularProgress isIndeterminate color='green.300' />
                            </Box>}

                            {!loading && <TableContainer paddingTop="15px">
                                <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                    <Thead>
                                        {
                                            // Recorremos las columnas que previamente definimos
                                            headerGroups.map(headerGroup => (
                                                // Añadimos las propiedades al conjunto de columnas
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    // Recorremos cada columna del conjunto para acceder a su información
                                                    headerGroup.headers.map((column) => (
                                                    // Añadimos las propiedades a cada celda de la cabecera
                                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                        {
                                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                                        column.render("Header")
                                                        }
                                                    </Th>
                                                    ))
                                                }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            // Recorremos las filas
                                            page.map(row => {
                                                // Llamamos a la función que prepara la fila previo renderizado
                                                prepareRow(row);
                                                return (
                                                // Añadimos las propiedades a la fila
                                                <Tr {...row.getRowProps()}>
                                                    {
                                                    // Recorremos cada celda de la fila
                                                    row.cells.map((cell) => {
                                                        // Añadimos las propiedades a cada celda de la fila
                                                        return (
                                                        <Td {...cell.getCellProps()}>
                                                            {
                                                            // Pintamos el contenido de la celda
                                                            cell.render("Cell")
                                                            }
                                                        </Td>
                                                        );
                                                    })
                                                    }
                                                    <Td>
                                                        <HStack>
                                                            {/*<Despatch idDespatch={row.values.id}/>*/}
                                                            <PrintPDFDespatch idDespatch={row.values.id}/>
                                                        </HStack>
                                                    </Td>
                                                </Tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>}

                            {!loading && <Box paddingTop="10px" textAlign="center">
                                <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                                <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                            </Box>}

                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CustomerDespatchs