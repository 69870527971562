import { useMemo } from "react";

export default function useColumnsProducts() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "_id"
     },
     {
       Header: "ID",
       accessor: "id"
     },
     {
       Header: "Producto",
       accessor: "name"
     },
     {
       Header: "Stock",
       accessor: "stock"
     },
     {
       Header: "Ubicación",
       accessor: "ubication"
     },
     {
       Header: "Precio",
       accessor: "priceView"
     },
     {
       Header: "Code",
       accessor: "code"
     },
     {
      Header: "isActive",
      accessor: "isActive"
     },
     {
      Header: "IVA",
      accessor: "avgIva"
     },
     {
      Header: "Categoría",
      accessor: "category"
     },
     {
      Header: "Subcategoría",
      accessor: "subcategory"
     }
   ],
   []
 );

 return columns;
}