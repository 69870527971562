import { Box, Button, Divider, Table, Thead, Tbody, Tr, Th, Td, TableContainer, HStack, Tooltip, 
    CircularProgress, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
    AlertDialogOverlay, useDisclosure, useToast } from '@chakra-ui/react'
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { DeleteIcon } from '@chakra-ui/icons'
import useColumnsCustomers from "../hooks/useColumnsCustomers";
import '../css/stylesheet.css'
import { GlobalFilter } from './GlobalFilter'
import { useEffect, useState, useRef } from 'react'
import CustomerAdd from './CustomerAdd'
import CustomerEdit from './CustomerEdit'
import CustomerBudgets from './CustomerBudgets'
import CustomerDespatchs from './CustomerDespatchs'
import CustomerCurrentAccount from './CustomerCurrentAccount'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const Customers = () => {
    const [data, setData] = useState([])
    const [submitModal, setSubmitModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [customer, setCustomer] = useState()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const toast = useToast()

    const navigate = useNavigate();
 
    useEffect(() => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)
        //let iva
        //let states

        /*fetch("https://api.sistemacorralonbianchi.com.ar/api/iva")
        .then(response => response.json())
        .then(data => {
            const arrIva = data.map(iva => {
                return {
                    id: iva.id,
                    name: iva.name
                }
            })
            iva = arrIva
        })
        .catch(error => {
            console.log(error)
        })*/

        /*fetch("https://api.sistemacorralonbianchi.com.ar/api/state")
        .then(response => response.json())
        .then(data => {
            const arrStates = data.map(state => {
                return {
                    id: state.id,
                    name: state.name
                }
            })
            states = arrStates
        })
        .catch(error => {
            console.log(error)
        })*/

        fetch("https://api.sistemacorralonbianchi.com.ar/api/customer", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCustomers = data.map(customer => {
                //const idIva = iva.find(obj => obj.id === customer.iva)
                /*let state = states.find(obj => obj.id === customer.state)
                if (!state){
                    state = ""
                } else {
                    state = " (" + state.name + ")"
                }*/
                return {
                    _id: customer._id,
                    id: customer.id,
                    name: customer.name,
                    iva: customer.iva,
                    cuit: customer.cuit,
                    //street: customer.street + " - " + customer.city + state,
                    celPhone: customer.celPhone,
                    //email: customer.email
                }
            })
            setData(arrCustomers)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })        

    }, [submitModal])

    const columns = useColumnsCustomers();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["_id"], sortBy: [{id: "name", asc: true}]} }, useGlobalFilter, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter
    } = table;

    const { globalFilter } = state

    const handleSubmitModal = () => {
        setSubmitModal(!submitModal)
    }

    const handleRemoveCustomer = (idCustomer) => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.sistemacorralonbianchi.com.ar/api/customer/moviments/" + idCustomer, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.moviments === true) {
                toast({
                    title: "No puede eliminar el cliente porque tiene movimientos cargados",
                    status: "error",
                    duration: 3000,
                    isClosable: true
                });
            } else {
                setCustomer(idCustomer)
                onOpen()
            }
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const removeCustomer = () => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.sistemacorralonbianchi.com.ar/api/customer/" + customer, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        toast({
            title: "El cliente se eliminó correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        setSubmitModal(!submitModal)
        onClose()
    }
      

    return(
        <>
            <Box className="content">

                <CustomerAdd handleSubmitModal={handleSubmitModal}/>

                <Box paddingTop="10px">
                    <GlobalFilter filter={ globalFilter } setFilter={ setGlobalFilter } />
                </Box>

                <Divider paddingTop="15px"/>

                {loading && <Box paddingTop="15px" textAlign='center'> 
                    <CircularProgress isIndeterminate color='green.300' />
                </Box>}

                {!loading && <TableContainer paddingTop="15px">
                    <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                        <Thead>
                            {
                                // Recorremos las columnas que previamente definimos
                                headerGroups.map(headerGroup => (
                                    // Añadimos las propiedades al conjunto de columnas
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        // Recorremos cada columna del conjunto para acceder a su información
                                        headerGroup.headers.map((column) => (
                                        // Añadimos las propiedades a cada celda de la cabecera
                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                            {
                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                            column.render("Header")
                                            }
                                        </Th>
                                        ))
                                    }
                                    </Tr>
                                ))
                            }
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {
                                // Recorremos las filas
                                page.map(row => {
                                    // Llamamos a la función que prepara la fila previo renderizado
                                    prepareRow(row);
                                    return (
                                    // Añadimos las propiedades a la fila
                                    <Tr {...row.getRowProps()}>
                                        {
                                        // Recorremos cada celda de la fila
                                        row.cells.map((cell) => {
                                            // Añadimos las propiedades a cada celda de la fila
                                            return (
                                            <Td {...cell.getCellProps()}>
                                                {
                                                // Pintamos el contenido de la celda
                                                cell.render("Cell")
                                                }
                                            </Td>
                                            );
                                        })
                                        }
                                        <Td>
                                            <HStack>
                                                <CustomerBudgets idCustomer={row.values._id} nameCustomer={row.values.name} handleSubmitModal={handleSubmitModal}/>
                                                <CustomerDespatchs idCustomer={row.values._id} nameCustomer={row.values.name} handleSubmitModal={handleSubmitModal}/>
                                                <CustomerCurrentAccount idCustomer={row.values._id} nameCustomer={row.values.name} cuit={row.values.cuit} handleSubmitModal={handleSubmitModal}/>
                                                <CustomerEdit idCustomer={row.values._id} handleSubmitModal={handleSubmitModal}/>
                                                <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={(e) => handleRemoveCustomer(row.values._id)}><DeleteIcon/></Button></Tooltip>
                                            </HStack>
                                        </Td>
                                    </Tr>
                                    );
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>}

                {!loading && <Box paddingTop="10px" textAlign="center">
                    <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                    <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                </Box>}
                
            </Box>

            <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Eliminar cliente
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Está seguro que desea eliminar el cliente?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                No
                            </Button>
                            <Button colorScheme='blue' onClick={removeCustomer} ml={3}>
                                Si
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </>
        

    )
}

export default Customers