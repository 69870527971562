import { Button, Tooltip, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, 
        AlertDialogContent, AlertDialogOverlay, useDisclosure, useToast, Modal, ModalOverlay, ModalContent, 
        ModalHeader, ModalCloseButton, ModalBody, Select, InputGroup, InputLeftAddon, Spacer, Flex, Stack } from "@chakra-ui/react"
import { BsFillFileEarmarkCheckFill } from "react-icons/bs"
import { PDFViewer } from '@react-pdf/renderer'
import { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import PDFInvoiceA from "./PDFInvoiceA";
import PDFInvoiceB from "./PDFInvoiceB";
import PDFCNA from "./PDFCNA";
import PDFCNB from "./PDFCNB";
const moment = require('moment')
var QRCode = require('qrcode')

const InvoiceAdd = ({ idVoucher, cuit, isInvoice, handleSubmitModal, voucherLetter }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    //const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
    const initialRef = useRef(null)
    const cancelRef = useRef()
    //const cancelRefAlert = useRef()
    const { isOpen: isOpenInvoiceA, onOpen: onOpenInvoiceA, onClose: onCloseInvoiceA } = useDisclosure()
    const { isOpen: isOpenInvoiceB, onOpen: onOpenInvoiceB, onClose: onCloseInvoiceB } = useDisclosure()
    const { isOpen: isOpenCreditNoteA, onOpen: onOpenCreditNoteA, onClose: onCloseCreditNoteA } = useDisclosure()
    const { isOpen: isOpenCreditNoteB, onOpen: onOpenCreditNoteB, onClose: onCloseCreditNoteB } = useDisclosure()
    const toast = useToast()
    let [ form, setForm ] = useState({})
    const [invoiceLetter, setInvoiceLetter] = useState("")

    const navigate = useNavigate();

    const handleOpen = () => {
        /*if (invoiceLetter === "No oficial") {
            toast({
                title: "No puede facturar este comprobante",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }*/
        
        if (cuit === undefined || cuit === "" || cuit === null) {
            toast({
                title: "Debe asignarle un CUIT al cliente",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (isInvoice === true) {
            toast({
                title: "Este comprobante ya se encuentra facturado",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (voucherLetter === "Recibo") {
            toast({
                title: "Este comprobante no se puede facturar",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        setInvoiceLetter("")
        onOpen()
    }

    const handleSubmit = () => {
        moment.locale("es")
        const accessToken = localStorage.getItem("user")

        if (invoiceLetter === "") {
            toast({
                title: "Seleccione el tipo de comprobante",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        const sale = {
            idSale: idVoucher
        }

        const creditNote = {
            idCreditNote: idVoucher
        }

        if (invoiceLetter === "A" && voucherLetter === "No oficial") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/afip/invoiceAAdd", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                },
                body: JSON.stringify(sale)
            })
            .then(response => response.json())
            .then(data => {
                const dateAfip = moment(data.dateAfip).add(1, 'd')
                const caeExpiration = moment(data.caeExpiration).add(1, 'd')
                let qr = ""
                QRCode.toDataURL(data.base64, function (err, url) {
                    qr = url
                })
                setForm(form = {
                    customerName: data.customer.name,
                    customerStreet: data.customer.street,
                    customerIva: data.customer.ivaName,
                    customerCity: data.customer.city,
                    customerState: data.customer.stateName,
                    customerCuit: data.customer.cuit,
                    invoiceNumber: data.invoiceNumberAfip,
                    dateAfip: dateAfip.format("L"),
                    products: data.products,
                    grossPrice: data.grossPrice,
                    ivaPrice: data.ivaPrice,
                    price: data.price,
                    caeExpiration: caeExpiration.format("L"),
                    cae: data.cae,
                    qr: qr
                })
                onOpenInvoiceA()
            })
            .catch(error => {
                console.log(error)
                /*authService.logout()
                navigate("/");
                window.location.reload();*/
            })
        } else if (invoiceLetter === "B" && voucherLetter === "No oficial") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/afip/invoiceBAdd", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                },
                body: JSON.stringify(sale)
            })
            .then(response => response.json())
            .then(data => {
                const dateAfip = moment(data.dateAfip).add(1, 'd')
                const caeExpiration = moment(data.caeExpiration).add(1, 'd')
                let qr = ""
                QRCode.toDataURL(data.base64, function (err, url) {
                    qr = url
                })
                setForm(form = {
                    customerName: data.customer.name,
                    customerStreet: data.customer.street,
                    customerIva: data.customer.ivaName,
                    customerCity: data.customer.city,
                    customerState: data.customer.stateName,
                    customerCuit: data.customer.cuit,
                    invoiceNumber: data.invoiceNumberAfip,
                    dateAfip: dateAfip.format("L"),
                    products: data.products,
                    grossPrice: data.grossPrice,
                    ivaPrice: data.ivaPrice,
                    price: data.price,
                    caeExpiration: caeExpiration.format("L"),
                    cae: data.cae,
                    qr: qr
                })
                onOpenInvoiceB()
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
        } else if (invoiceLetter === "A" && voucherLetter === "N.C. No oficial") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/afip/creditNoteAAdd", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                },
                body: JSON.stringify(creditNote)
            })
            .then(response => response.json())
            .then(data => {
                const dateAfip = moment(data.dateAfip).add(1, 'd')
                const caeExpiration = moment(data.caeExpiration).add(1, 'd')
                let qr = ""
                QRCode.toDataURL(data.base64, function (err, url) {
                    qr = url
                })
                setForm(form = {
                    customerName: data.customer.name,
                    customerStreet: data.customer.street,
                    customerIva: data.customer.ivaName,
                    customerCity: data.customer.city,
                    customerState: data.customer.stateName,
                    customerCuit: data.customer.cuit,
                    creditNoteNumber: data.creditNoteNumberAfip,
                    despatchNumber: data.despatchNumber,
                    dateAfip: dateAfip.format("L"),
                    products: data.products,
                    grossPrice: data.grossPrice,
                    ivaPrice: data.ivaPrice,
                    price: data.price,
                    caeExpiration: caeExpiration.format("L"),
                    cae: data.cae,
                    qr: qr
                })
                onOpenCreditNoteA()
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
        } else if (invoiceLetter === "B" && voucherLetter === "N.C. No oficial") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/afip/creditNoteBAdd", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                },
                body: JSON.stringify(creditNote)
            })
            .then(response => response.json())
            .then(data => {
                const dateAfip = moment(data.dateAfip).add(1, 'd')
                const caeExpiration = moment(data.caeExpiration).add(1, 'd')
                let qr = ""
                QRCode.toDataURL(data.base64, function (err, url) {
                    qr = url
                })
                setForm(form = {
                    customerName: data.customer.name,
                    customerStreet: data.customer.street,
                    customerIva: data.customer.ivaName,
                    customerCity: data.customer.city,
                    customerState: data.customer.stateName,
                    customerCuit: data.customer.cuit,
                    creditNoteNumber: data.creditNoteNumberAfip,
                    despatchNumber: data.despatchNumber,
                    dateAfip: dateAfip.format("L"),
                    products: data.products,
                    grossPrice: data.grossPrice,
                    ivaPrice: data.ivaPrice,
                    price: data.price,
                    caeExpiration: caeExpiration.format("L"),
                    cae: data.cae,
                    qr: qr
                })
                onOpenCreditNoteB()
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
        }

        //handleSubmitModal()
        onClose()
    }

    const handleCloseInvoiceA = () => {
        handleSubmitModal()
        onCloseInvoiceA()
    }

    const handleCloseInvoiceB = () => {
        handleSubmitModal()
        onCloseInvoiceB()
    }

    const handleCloseCreditNoteA = () => {
        handleSubmitModal()
        onCloseCreditNoteA()
    }

    const handleCloseCreditNoteB = () => {
        handleSubmitModal()
        onCloseCreditNoteB()
    }

    return(
        <>
            <Tooltip label='Facturar'><Button size="sm" colorScheme="green" onClick={handleOpen}><BsFillFileEarmarkCheckFill/></Button></Tooltip>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Comprobante</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack>
                            <InputGroup>
                                <InputLeftAddon children='Tipo de factura' width={140} />
                                <Select placeholder='-Selecciona-' onChange={(e) => setInvoiceLetter(e.target.value)}>
                                    {/*<option value='No oficial'>No oficial</option>*/}
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    {/*<option value='C'>C</option>*/}
                                </Select>
                            </InputGroup> 

                            <Spacer/>

                            <Flex p={4}>
                                <Spacer/>
                                <Button colorScheme='blue' onClick={handleSubmit}>Generar</Button>
                            </Flex>
                            
                        </Stack>
                    </ModalBody>

                </ModalContent>
            </Modal>

            {/*<AlertDialog
                isOpen={isOpenAlert}
                leastDestructiveRef={cancelRefAlert}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirmar factura
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        ¿Desea confirmar la factura?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRefAlert} onClick={onCloseAlert}>
                        No
                    </Button>
                    <Button colorScheme='blue' onClick={handleSubmit} ml={3}>
                        Confirmar
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
    </AlertDialog>*/}

            <Modal
                isOpen={isOpenInvoiceA}
                onClose={handleCloseInvoiceA}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Factura</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFInvoiceA {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                isOpen={isOpenInvoiceB}
                onClose={handleCloseInvoiceB}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Factura</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFInvoiceB {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                isOpen={isOpenCreditNoteA}
                onClose={handleCloseCreditNoteA}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nota de crédito</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFCNA {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                isOpen={isOpenCreditNoteB}
                onClose={handleCloseCreditNoteB}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nota de crédito</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFCNB {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default InvoiceAdd