import { Button, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
    useDisclosure, Stack, CircularProgress, Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Box, HStack, InputGroup, InputLeftAddon, Input, Textarea, Spacer, Select, AlertDialog,
    AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useToast, 
    FormLabel } from "@chakra-ui/react"
import { useTable, useSortBy, usePagination } from "react-table"
import { cartInitialState, cartReducer } from '../reducers/cartReducer'
import { TYPES } from '../actions/cartAction'
import { Search2Icon, DeleteIcon } from '@chakra-ui/icons'
import { useRef, useState, useReducer } from 'react'
import { useForm } from 'react-hook-form'
import PurchaseProductAdd from "./PurchaseProductAdd"
import AlertPop from './AlertPop'
import useColumnsProductPurchases from "../hooks/useColumnsProductPurchases"
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')
moment.locale('es')

const Purchase = ({ idPurchase, handleSubmitModal }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenAlertDialog, onOpen: onOpenAlertDialog, onClose: onCloseAlertDialog } = useDisclosure()
    const initialRef = useRef(null)
    const [stateReducer, dispatch] = useReducer(cartReducer, cartInitialState)
    const { data } = stateReducer
    const [originalData, setOriginalData] = useState([])
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm()
    const [suppliers, setSuppliers] = useState([])
    const [purchaseDate, setPurchaseDate] = useState()
    /*const [supplier, setSupplier] = useState("")
    const [date, setDate] = useState("")
    const [invoiceNumber, setInvoiceNumber] = useState("")
    const [invoiceLetter, setInvoiceLetter] = useState("")
    const [observations, setObservations] = useState("")*/
    const [grossPrice, setGrossPrice] = useState("")
    const [ivaPrice, setIvaPrice] = useState("")
    const [price, setPrice] = useState("")
    const toast = useToast()
    const cancelRef = useRef()

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)
        clearCart()
        reset()

        fetch("https://api.sistemacorralonbianchi.com.ar/api/supplier", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
        const arrSuppliers = data.map(supplier => {
            return { 
                id: supplier._id,
                name: supplier.name
            }
        })
        setSuppliers(arrSuppliers)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        fetch("https://api.sistemacorralonbianchi.com.ar/api/purchase/" + idPurchase, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            /*const purchase = {
                supplier: data.supplier,
                date: data.date,
                invoiceNumber: data.invoiceNumber,
                invoiceLetter: data.invoiceLetter,
                observations: data.observations,
                grossPrice: parseFloat(data.grossPrice).toFixed(2),
                ivaPrice: parseFloat(data.ivaPrice).toFixed(2),
                price: parseFloat(data.price).toFixed(2)
            }*/
            setValue("supplier", data.supplier)
            setValue("invoiceNumber", data.invoiceNumber)
            setValue("invoiceLetter", data.invoiceLetter)
            setValue("observations", data.observations)
            setGrossPrice(parseFloat(data.grossPrice).toFixed(2))
            setIvaPrice(parseFloat(data.ivaPrice).toFixed(2))
            setPrice(parseFloat(data.price).toFixed(2))

            const dateFormated = moment(data.date).format('DD/MM/YYYY')
            const [day, month, year] = dateFormated.split('/')
            setPurchaseDate(new Date(+year, month - 1, +day))
            
            setOriginalData(data)
            
            const arrProducts = data.products.map(product => {
                return { 
                    _id: product._id,
                    id: product.id,
                    name: product.name,
                    quantity: parseFloat(product.quantity),
                    unitPrice: parseFloat(product.unitPrice),
                    avgIva: parseFloat(product.avgIva),
                    totalPrice: parseFloat(product.totalPrice)
                }
            })
            /*setSupplier(purchase.supplier)
            setDate(purchase.date)
            setInvoiceNumber(purchase.invoiceNumber)
            setInvoiceLetter(purchase.invoiceLetter)
            setObservations(purchase.observations)
            setGrossPrice(purchase.grossPrice)
            setIvaPrice(purchase.ivaPrice)
            setPrice(purchase.price)*/

            for (let i=0; i < arrProducts.length; i++) {
                initialCart(arrProducts[i])
            }
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    const columns = useColumnsProductPurchases();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["_id", "dateNoView", "date", "supplier", "invoiceNumber", "invoiceLetter"]} }, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow
    } = table

    const onSubmit = () => {
        if (data.length === 0){
            toast({
                title: "No hay productos cargados",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (purchaseDate === null){
            toast({
                title: "Seleccione una fecha",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        onOpenAlertDialog()
    }

    const confirmPurchase = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("en")
        const arrProductsData = []
        const arrProducts = []

        for (let i=0; i < data.length; i++) {
            arrProductsData.push({
                _id: data[i]._id,
                id: data[i].id,
                name: data[i].name,
                quantity: parseFloat(data[i].quantity),
                unitPrice: parseFloat(data[i].unitPrice),
                avgIva: parseFloat(data[i].avgIva),
                totalPrice: parseFloat(data[i].totalPrice)
            })
        }

        const purchase = {
            id: idPurchase,
            supplier: getValues("supplier"),
            date: moment(purchaseDate).format('L'),
            invoiceNumber: getValues("invoiceNumber") || "",
            invoiceLetter: getValues("invoiceLetter"),
            observations: getValues("observations") || "",
            grossPrice: parseFloat(grossPrice),
            ivaPrice: parseFloat(ivaPrice),
            price: parseFloat(price),
            products: arrProductsData
        }

        for (let i=0; i < originalData.products.length; i++) {
            arrProducts.push({
                _id: originalData.products[i]._id,
                id: originalData.products[i].id,
                name: originalData.products[i].name,
                quantity: parseFloat(originalData.products[i].quantity),
                unitPrice: parseFloat(originalData.products[i].unitPrice),
                avgIva: parseFloat(originalData.products[i].avgIva),
                totalPrice: parseFloat(originalData.products[i].totalPrice)
            })
        }

        const purchaseOriginalData = {
            id: originalData._id,
            supplier: originalData.supplier,
            date: moment(originalData.date).add(3,"h").format('L'),
            invoiceNumber: originalData.invoiceNumber,
            invoiceLetter: originalData.invoiceLetter,
            observations: originalData.observations,
            grossPrice: parseFloat(originalData.grossPrice),
            ivaPrice: parseFloat(originalData.ivaPrice),
            price: parseFloat(originalData.price),
            products: arrProducts
        }
        
        if (JSON.stringify(purchase) === JSON.stringify(purchaseOriginalData)) {
            onCloseAlertDialog()
            onClose()
        } else {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/purchase/" + idPurchase, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                },
                body: JSON.stringify(purchase)
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })

            toast({
                title: "La compra se modificó correctamente",
                status: "success",
                duration: 3000,
                isClosable: true
            });

            handleSubmitModal()
            onCloseAlertDialog()
            onClose()
        }
    }

    const handleChange = (e) => {
        calculatingPrices()
    }

    const initialCart = (product) => {
        dispatch({ type:TYPES.ADD_TO_CART, payload: product })
    }

    const addToCart = (product) => {
        dispatch({ type:TYPES.ADD_TO_CART, payload: product })
        calculatingPrices(product, true)
    }

    const removeFromCart = (product) => {
        dispatch({ type:TYPES.REMOVE_ONE_FROM_CART, payload: product.id })
        calculatingPrices(product, false)
    }

    const clearCart = () => {
        dispatch({ type:TYPES.CLEAR_CART })
    }

    const calculatingPrices = (product, add) => {
        let sumGrossPrice = 0
        let sumIvaPrice = 0

        if (getValues("invoiceLetter") === "A") {
            if (product) {
                for (let i=0; i < data.length; i++) {
                    if (data[i].id === product.id) continue
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                    sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(data[i].totalPrice) * parseFloat(data[i].avgIva) / 100)
                }

                if (add === true) {
                    const productSearch = data.find(obj => obj.id === product.id)
                    if (productSearch) {
                        const quantityProduct = parseFloat(productSearch.quantity) + parseFloat(product.quantity)
                        const grossPriceProduct = parseFloat(quantityProduct) * parseFloat(product.unitPrice)
                        const ivaPriceProduct = parseFloat(grossPriceProduct) * parseFloat(product.avgIva) / 100
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(grossPriceProduct)
                        sumIvaPrice = parseFloat(sumIvaPrice) + parseFloat(ivaPriceProduct)
                    } else {
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(product.totalPrice)
                        sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(product.totalPrice) * parseFloat(product.avgIva) / 100)
                    }
                }
            } else {
                for (let i=0; i < data.length; i++) {
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                    sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(data[i].totalPrice) * parseFloat(data[i].avgIva) / 100)
                }
            }
            setGrossPrice(parseFloat(sumGrossPrice).toFixed(2))
            setIvaPrice(parseFloat(sumIvaPrice).toFixed(2))
            setPrice(parseFloat(sumGrossPrice + sumIvaPrice).toFixed(2))
            
        } else if (getValues("invoiceLetter") === "B" || getValues("invoiceLetter") === "C" || getValues("invoiceLetter") === "No oficial") {
            if (product) {
                for (let i=0; i < data.length; i++) {
                    if (data[i].id === product.id) continue
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                }
                if (add === true) {
                    const productSearch = data.find(obj => obj.id === product.id)
                    if (productSearch) {
                        const quantityProduct = parseFloat(productSearch.quantity) + parseFloat(product.quantity)
                        const grossPriceProduct = parseFloat(quantityProduct) * parseFloat(product.unitPrice)
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(grossPriceProduct)
                    } else {
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(product.totalPrice)
                    }
                }
            } else {
                for (let i=0; i < data.length; i++) {
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                }
            }
            
            setGrossPrice(parseFloat(sumGrossPrice).toFixed(2))
            setIvaPrice(parseFloat(0).toFixed(2))
            setPrice(parseFloat(sumGrossPrice).toFixed(2))
        } else {
            setGrossPrice(parseFloat(0).toFixed(2))
            setIvaPrice(parseFloat(0).toFixed(2))
            setPrice(parseFloat(0).toFixed(2))
        }

        /*if (getValues("invoiceLetter") === "A") {
            for (let i=0; i < data.length; i++) {
                sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(data[i].totalPrice) * parseFloat(data[i].avgIva) / 100)
            }
            if (product) {
                if (add === true) {
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(product.totalPrice)
                    sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(product.totalPrice) * parseFloat(product.avgIva) / 100)
                } else {
                    sumGrossPrice = parseFloat(sumGrossPrice) - parseFloat(product.totalPrice)
                    sumIvaPrice = parseFloat(sumIvaPrice) - (parseFloat(product.totalPrice) * parseFloat(product.avgIva) / 100)
                }
            }

            setGrossPrice(parseFloat(sumGrossPrice).toFixed(2))
            setIvaPrice(parseFloat(sumIvaPrice).toFixed(2))
            setPrice(parseFloat(sumGrossPrice + sumIvaPrice).toFixed(2))
        } else if (getValues("invoiceLetter") === "B" || getValues("invoiceLetter") === "C" || getValues("invoiceLetter") === "No oficial") {
            for (let i=0; i < data.length; i++) {
                sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
            }
            if (product) {
                if (add === true) {
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(product.totalPrice)
                } else {
                    sumGrossPrice = parseFloat(sumGrossPrice) - parseFloat(product.totalPrice)
                }
            }
            
            setGrossPrice(parseFloat(sumGrossPrice).toFixed(2))
            setIvaPrice(parseFloat(0).toFixed(2))
            setPrice(parseFloat(sumGrossPrice).toFixed(2))
        } else {
            setGrossPrice(parseFloat(0).toFixed(2))
            setIvaPrice(parseFloat(0).toFixed(2))
            setPrice(parseFloat(0).toFixed(2))
        }*/
    }

    return (
        <>
            <Tooltip label='Ver comprobante'><Button size="sm" colorScheme="blackAlpha" onClick={handleOpen}><Search2Icon/></Button></Tooltip>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='4xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Detalle de comprobante</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={4}>

                                <HStack>
                                    <InputGroup>
                                        <InputLeftAddon children='Proveedor' width={140} />
                                        <Select placeholder='-Selecciona-' {...register('supplier', {required: 'Por favor seleccione el proveedor'})}>
                                        {suppliers.map(supplier => {
                                            return (
                                            <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                        )})}
                                        </Select>
                                    </InputGroup>

                                    <InputGroup mt={4} display='flex' alignItems='center'>
                                        <InputLeftAddon children='Fecha' width={140} />
                                        <DatePicker locale='es' selected={purchaseDate} onChange={(date) => setPurchaseDate(date)} dateFormat="dd/MM/yyyy" />
                                    </InputGroup>
                                </HStack>
                                {errors.supplier && <AlertPop title={errors.supplier.message} />}

                                <HStack>
                                    <InputGroup>
                                        <InputLeftAddon children='N° de Factura' width={140} />
                                        <Input {...register('invoiceNumber')} />
                                    </InputGroup>

                                    <InputGroup>
                                        <InputLeftAddon children='Letra' width={140} />
                                        <Select placeholder='-Selecciona-' {...register('invoiceLetter', {onChange: (e) => handleChange(e), required: 'Por favor seleccione el tipo de comprobante'})}>
                                            <option value='No oficial'>No oficial</option>
                                            <option value='A'>A</option>
                                            <option value='B'>B</option>
                                            <option value='C'>C</option>
                                        </Select>
                                    </InputGroup>
                                </HStack>
                                {errors.invoiceLetter && <AlertPop title={errors.invoiceLetter.message} />}

                                <InputGroup>
                                    <InputLeftAddon children='Observaciones' width={140} />
                                    <Textarea size='sm' {...register('observations')} />
                                </InputGroup>

                                <InputGroup>
                                    <InputLeftAddon children='Importe bruto $' width={140} />
                                    <Input isDisabled placeholder='$' type='number' step='0.01' value={grossPrice} {...register('grossPrice')} />
                                </InputGroup>

                                <InputGroup>
                                    <InputLeftAddon children='Importe I.V.A. $' width={140} />
                                    <Input isDisabled placeholder='$' type='number' step='0.01' value={ivaPrice} {...register('ivaPrice')} />
                                </InputGroup>

                                <InputGroup>
                                    <InputLeftAddon children='Importe $' width={140} />
                                    <Input isDisabled placeholder='$' type='number' step='0.01' value={price} {...register('price')} />
                                </InputGroup>

                                <Spacer/>

                                <HStack>
                                    <PurchaseProductAdd addToCart={addToCart}/>
                                    <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                                </HStack>

                                <FormLabel>Los productos agregados o eliminados se actualizarán en el stock</FormLabel>

                                <Spacer/>

                            </Stack>
                        </form>

                        {loading && <Box paddingTop="15px" textAlign='center'> 
                            <CircularProgress isIndeterminate color='green.300' />
                        </Box>}

                        {!loading && <TableContainer paddingTop="15px">
                            <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                <Thead>
                                    {
                                        // Recorremos las columnas que previamente definimos
                                        headerGroups.map(headerGroup => (
                                            // Añadimos las propiedades al conjunto de columnas
                                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                // Recorremos cada columna del conjunto para acceder a su información
                                                headerGroup.headers.map((column) => (
                                                // Añadimos las propiedades a cada celda de la cabecera
                                                <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                    {
                                                    // Pintamos el título de nuestra columna (propiedad "Header")
                                                    column.render("Header")
                                                    }
                                                </Th>
                                                ))
                                            }
                                            </Tr>
                                        ))
                                    }
                                </Thead>
                                <Tbody {...getTableBodyProps()}>
                                    {
                                        // Recorremos las filas
                                        page.map(row => {
                                            // Llamamos a la función que prepara la fila previo renderizado
                                            prepareRow(row);
                                            return (
                                            // Añadimos las propiedades a la fila
                                            <Tr {...row.getRowProps()}>
                                                {
                                                // Recorremos cada celda de la fila
                                                row.cells.map((cell) => {
                                                    // Añadimos las propiedades a cada celda de la fila
                                                    return (
                                                    <Td {...cell.getCellProps()}>
                                                        {
                                                        // Pintamos el contenido de la celda
                                                        cell.render("Cell")
                                                        }
                                                    </Td>
                                                    );
                                                })
                                                }
                                                <Td>
                                                    <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={() => removeFromCart(row.values)}><DeleteIcon/></Button></Tooltip>
                                                </Td>
                                            </Tr>
                                            );
                                        })
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>}

                        {!loading && <Box paddingTop="10px" textAlign="center">
                            <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                            <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                        </Box>}
                    </ModalBody>
                </ModalContent>
            </Modal>

            <AlertDialog
                isOpen={isOpenAlertDialog}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlertDialog}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirmar compra
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        ¿Desea confirmar los cambios en la compra?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onCloseAlertDialog}>
                        No
                    </Button>
                    <Button colorScheme='blue' onClick={confirmPurchase} ml={3}>
                        Confirmar
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </>
    )
}

export default Purchase