import { Box, Button, Flex, Spacer, Divider, FormLabel, Table, Thead, Tbody, Tr, Th, Td, TableContainer, 
    Select, HStack, Tooltip, CircularProgress, useDisclosure, AlertDialog, 
    AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useToast } from '@chakra-ui/react'
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { GlobalFilter } from './GlobalFilter'
import { DeleteIcon } from '@chakra-ui/icons'
import useColumnsCategories from "../hooks/useColumnsCategories"
import SubcategoryAdd from './SubcategoryAdd'
import SubcategoryEdit from './SubcategoryEdit';
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const Subcategories = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [submitModal, setSubmitModal] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const [subcategory, setSubcategory] = useState()
    const toast = useToast()
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(null)

    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)

        fetch("https://api.sistemacorralonbianchi.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCategories = data.map(category => {
                return { 
                    id: category._id,
                    name: category.name
                }
            })
            setCategories(arrCategories)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        if (category === null || category === "") {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/subcategory", {
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                }
            })
            .then(response => response.json())
            .then(data => {
                const arrSubcategories = data.map(subcategory => {
                    return { 
                        id: subcategory._id,
                        category: subcategory.category,
                        subcategory: subcategory.name
                    }
                })
                setData(arrSubcategories)
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
        } else {
            fetch("https://api.sistemacorralonbianchi.com.ar/api/subcategory/category/" + category, {
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                }
            })
            .then(response => response.json())
            .then(data => {
                const arrSubcategories = data.map(subcategory => {
                    return { 
                        id: subcategory._id,
                        category: subcategory.category,
                        subcategory: subcategory.name
                    }
                })
                setData(arrSubcategories)
            })
            .catch(error => {
                authService.logout()
                navigate("/");
                window.location.reload();
            })
        }
        setLoading(false)
    }, [category, submitModal])

    const columns = useColumnsCategories();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["id"], sortBy: [{id: "subcategory", asc: true}]} }, useGlobalFilter, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        setGlobalFilter,
        state
    } = table

    const { globalFilter } = state

    const handleChangeCategory = (e) => {
        setCategory(e.target.value)
    }

    const handleSubmitModal = () => {
        setSubmitModal(!submitModal)
    }

    const handleRemoveSubategory = (idSubcategory) => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.sistemacorralonbianchi.com.ar/api/subcategory/moviments/" + idSubcategory, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.moviments === true) {
                toast({
                    title: "No puede eliminar la subcategoría porque tiene productos cargados",
                    status: "error",
                    duration: 3000,
                    isClosable: true
                });
            } else {
                setSubcategory(idSubcategory)
                onOpen()
            }
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const removeSubcategory = () => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.sistemacorralonbianchi.com.ar/api/subcategory/" + subcategory, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        toast({
            title: "La subcategoría se eliminó correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        setSubmitModal(!submitModal)
        onClose()
    }

    return (
        <>
            <Box className="content">

                <SubcategoryAdd handleSubmitModal={handleSubmitModal}/>

                <Box paddingTop="10px">
                    <GlobalFilter filter={ globalFilter } setFilter={ setGlobalFilter } />
                </Box>

                <Divider paddingTop="15px"/>

                {loading && <Box paddingTop="15px" textAlign='center'> 
                    <CircularProgress isIndeterminate color='green.300' />
                </Box>}

                <FormLabel>Categoría:</FormLabel>
                <Select placeholder='-Selecciona-' onChange={(e) => handleChangeCategory(e)}>
                {categories.map(category => {
                    return (
                    <option key={category.id} value={category.id}>{category.name}</option>
                    )})}
                </Select>

                {!loading && <TableContainer paddingTop="15px">
                    <Table variant='striped' size="lg" colorScheme='blackAlpha' {...getTableProps()}>
                        <Thead>
                            {
                                // Recorremos las columnas que previamente definimos
                                headerGroups.map(headerGroup => (
                                    // Añadimos las propiedades al conjunto de columnas
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        // Recorremos cada columna del conjunto para acceder a su información
                                        headerGroup.headers.map((column) => (
                                        // Añadimos las propiedades a cada celda de la cabecera
                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                            {
                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                            column.render("Header")
                                            }
                                        </Th>
                                        ))
                                    }
                                    </Tr>
                                ))
                            }
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {
                                // Recorremos las filas
                                page.map(row => {
                                    // Llamamos a la función que prepara la fila previo renderizado
                                    prepareRow(row);
                                    return (
                                    // Añadimos las propiedades a la fila
                                    <Tr {...row.getRowProps()}>
                                        {
                                        // Recorremos cada celda de la fila
                                        row.cells.map((cell) => {
                                            // Añadimos las propiedades a cada celda de la fila
                                            return (
                                            <Td {...cell.getCellProps()}>
                                                {
                                                // Pintamos el contenido de la celda
                                                cell.render("Cell")
                                                }
                                            </Td>
                                            );
                                        })
                                        }
                                        <Td>
                                            <HStack>
                                                <SubcategoryEdit idSubcategory={row.values.id} handleSubmitModal={handleSubmitModal}/>
                                                <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={(e) => handleRemoveSubategory(row.values.id)}><DeleteIcon/></Button></Tooltip>
                                            </HStack>
                                        </Td>
                                    </Tr>
                                    );
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>}

                {!loading && <Box paddingTop="10px" textAlign="center">
                    <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                    <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                </Box>}

                <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Eliminar subcategoría
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                ¿Está seguro que desea eliminar la subcategoría?
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                    No
                                </Button>
                                <Button colorScheme='blue' onClick={removeSubcategory} ml={3}>
                                    Si
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>

                </Box>
        </>
    )
}

export default Subcategories