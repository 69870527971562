import { Box, Button, Flex, Spacer, Divider, FormLabel, Table, Thead, Tbody, Tr, Th, Td, TableContainer, 
    Select, HStack, Tooltip, Stack, CircularProgress, useDisclosure, AlertDialog, 
    AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useToast } from '@chakra-ui/react'
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { useState, useRef } from 'react'
import useColumnsPurchasePlanAdd from "../hooks/useColumnsPurchasePlanAdd";
import { GlobalFilter } from './GlobalFilter'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
const moment = require('moment')

const PurchasePlanAddAuto = ({ handleSubmitModal }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [totalPrice, setTotalPrice] = useState(0)
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
    const cancelRefAlert = useRef()
    const toast = useToast()

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)

        fetch("https://api.sistemacorralonbianchi.com.ar/api/purchase/purchasePlan/auto", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrProducts = data.map(product => {
                return {
                    _id: product._id,
                    id: product.id,
                    name: product.name,
                    stock: product.stock,
                    minimum: product.minimum,
                    maximum: product.maximum,
                    quantity: product.quantity,
                    price: parseFloat(product.price).toFixed(2),
                    totalPrice: parseFloat(product.totalPrice).toFixed(2)
                }
            })
            
            let sumTotalPrice = 0

            for (let i=0; i < arrProducts.length; i++) {
                sumTotalPrice = parseFloat(sumTotalPrice) + parseFloat(arrProducts[i].totalPrice)
            }

            setTotalPrice(parseFloat(sumTotalPrice).toFixed(2))
            setData(arrProducts)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    const columns = useColumnsPurchasePlanAdd();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["_id"], sortBy: [{id: "name", asc: true}]} }, useGlobalFilter, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter
    } = table

    const { globalFilter } = state

    const handleSubmit = () => {
        if (data.length === 0){
            toast({
                title: "No hay productos cargados",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        onOpenAlert()
    }

    const confirmPurchasePlan = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("en")
        const arrProductsData = []

        for (let i=0; i < data.length; i++) {
            arrProductsData.push({
                _id: data[i]._id,
                quantity: parseFloat(data[i].quantity),
                price: parseFloat(data[i].price)
            })
        }

        const purchasePlan = {
            date: moment().format('L'),
            products: arrProductsData,
            totalPrice: parseFloat(totalPrice)
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/purchasePlan", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(purchasePlan)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        toast({
            title: "La planificación de compra se cargó correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        handleSubmitModal()
        onCloseAlert()
        onClose()
    }

    return (
        <>
            <Button size='sm' colorScheme='cyan' color='white' onClick={handleOpen}>Planificar compra automáticamente</Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size='5xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Planificar compra</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack spacing={4}>
                            <Box paddingTop="10px">
                                <GlobalFilter filter={ globalFilter } setFilter={ setGlobalFilter } />
                            </Box>

                            {loading && <Box paddingTop="15px" textAlign='center'> 
                                <CircularProgress isIndeterminate color='green.300' />
                            </Box>}

                            {!loading && <TableContainer paddingTop="15px">
                                <Table variant='striped' size="md" colorScheme='blackAlpha' {...getTableProps()}>
                                    <Thead>
                                        {
                                            // Recorremos las columnas que previamente definimos
                                            headerGroups.map(headerGroup => (
                                                // Añadimos las propiedades al conjunto de columnas
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    // Recorremos cada columna del conjunto para acceder a su información
                                                    headerGroup.headers.map((column) => (
                                                    // Añadimos las propiedades a cada celda de la cabecera
                                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                        {
                                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                                        column.render("Header")
                                                        }
                                                    </Th>
                                                    ))
                                                }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            // Recorremos las filas
                                            page.map(row => {
                                                // Llamamos a la función que prepara la fila previo renderizado
                                                prepareRow(row);
                                                return (
                                                // Añadimos las propiedades a la fila
                                                <Tr {...row.getRowProps()}>
                                                    {
                                                    // Recorremos cada celda de la fila
                                                    row.cells.map((cell) => {
                                                        // Añadimos las propiedades a cada celda de la fila
                                                        return (
                                                        <Td {...cell.getCellProps()}>
                                                            {
                                                            // Pintamos el contenido de la celda
                                                            cell.render("Cell")
                                                            }
                                                        </Td>
                                                        );
                                                    })
                                                    }
                                                    {/*<Td>
                                                        <SaleProductInfo _idProduct={row.values._id} idProduct={row.values.id} addToCartProduct={addToCartProduct} />
                                                </Td>*/}
                                                </Tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>}

                            <Box paddingTop="10px" textAlign="center">
                                <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                                <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                            </Box>

                            <Flex paddingTop="10px">
                                <Spacer/>
                                <Box>
                                    <FormLabel textAlign="right">Precio total: </FormLabel>
                                </Box>
                                <Box>
                                    <FormLabel textAlign="left">${totalPrice}</FormLabel>
                                </Box>
                            </Flex>

                            <Flex>
                                <Spacer/>
                                <Button colorScheme='gray' mr={3} onClick={onClose}>Cancelar</Button>
                                <Button colorScheme='blue' mr={3} onClick={handleSubmit}>Guardar</Button>
                            </Flex>
                        </Stack>
                    </ModalBody>

                </ModalContent>
            </Modal>

            <AlertDialog
                isOpen={isOpenAlert}
                leastDestructiveRef={cancelRefAlert}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirmar planificación de compra
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        ¿Desea confirmar la planificación de compra?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRefAlert} onClick={onCloseAlert}>
                        No
                    </Button>
                    <Button colorScheme='blue' onClick={confirmPurchasePlan} ml={3}>
                        Confirmar
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )

}

export default PurchasePlanAddAuto