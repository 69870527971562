import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure,
    Button } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import PDFCustomerProductsCurrentAccount from './PDFCustomerProductsCurrentAccount'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import moment from 'moment'

const PrintPDFProductsCurrentAccount = ({ idCustomer }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    let [ form, setForm ] = useState({})

    const navigate = useNavigate();

    const handleClose = () => {
        onClose()
    }

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")
        
        fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/get/customer/products/currentaccount/" + idCustomer, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setForm(form = {
                customerName: data.customerName,
                customerStreet: data.customerStreet,
                customerIva: data.customerIvaName,
                customerCity: data.customerCity,
                customerCuit: data.customerCuit,
                customerState: data.customerState,
                products: data.products
            })
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        onOpen()
    }

    return (
        <>
            <Button size="sm" colorScheme='blackAlpha' onClick={handleOpen}>Productos pendientes de entregar</Button>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={handleClose}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Productos pendientes de entregar</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFCustomerProductsCurrentAccount {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default PrintPDFProductsCurrentAccount