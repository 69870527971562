import { Button, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
    useDisclosure, Stack, CircularProgress, Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Box, FormLabel } from "@chakra-ui/react"
import { useTable, useSortBy, usePagination } from "react-table"
import { Search2Icon } from '@chakra-ui/icons'
import { BsCheckCircle } from "react-icons/bs"
import { useRef, useState } from 'react'
import useColumnsInvoices from "../hooks/useColumnsInvoices"
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
const moment = require('moment')

const CustomerInvoices = ({ idCustomer, handleSelectCustomer }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")
        setLoading(true)

        fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/customer/" + idCustomer, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrInvoices = data.map(invoice => {
                return { 
                    _id: invoice._id,
                    date: invoice.date,
                    formatDate: moment(invoice.date).add(3,"h").format('L'),
                    invoiceNumber: invoice.invoiceNumber,
                    invoiceLetter: invoice.invoiceLetter,
                    price: "$" + parseFloat(invoice.price).toFixed(2)
                }
            })
            setData(arrInvoices)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    const columns = useColumnsInvoices();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["_id", "date"], sortBy: [{id: "date", desc: true}]} }, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow
    } = table

    const handleSelectInvoice = (invoice) => {
        handleSelectCustomer(idCustomer, invoice._id, invoice.invoiceNumber)

        onClose()
    }

    return (
        <>
            <Tooltip label='Buscar facturas'><Button colorScheme="blackAlpha" onClick={handleOpen}><Search2Icon/></Button></Tooltip>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='4xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Seleccionar factura</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack spacing={2}>

                            {loading && <Box paddingTop="15px" textAlign='center'> 
                                <CircularProgress isIndeterminate color='green.300' />
                            </Box>}

                            {!loading && <TableContainer paddingTop="15px">
                                <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                    <Thead>
                                        {
                                            // Recorremos las columnas que previamente definimos
                                            headerGroups.map(headerGroup => (
                                                // Añadimos las propiedades al conjunto de columnas
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    // Recorremos cada columna del conjunto para acceder a su información
                                                    headerGroup.headers.map((column) => (
                                                    // Añadimos las propiedades a cada celda de la cabecera
                                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                        {
                                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                                        column.render("Header")
                                                        }
                                                    </Th>
                                                    ))
                                                }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            // Recorremos las filas
                                            page.map(row => {
                                                // Llamamos a la función que prepara la fila previo renderizado
                                                prepareRow(row);
                                                return (
                                                // Añadimos las propiedades a la fila
                                                <Tr {...row.getRowProps()}>
                                                    {
                                                    // Recorremos cada celda de la fila
                                                    row.cells.map((cell) => {
                                                        // Añadimos las propiedades a cada celda de la fila
                                                        return (
                                                        <Td {...cell.getCellProps()}>
                                                            {
                                                            // Pintamos el contenido de la celda
                                                            cell.render("Cell")
                                                            }
                                                        </Td>
                                                        );
                                                    })
                                                    }
                                                    <Td>
                                                        <Tooltip label='Seleccionar'><Button size="sm" colorScheme="whatsapp" onClick={(e) => handleSelectInvoice(row.values)}><BsCheckCircle/></Button></Tooltip>
                                                    </Td>
                                                </Tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>}

                            {!loading && <Box paddingTop="10px" textAlign="center">
                                <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                                <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                            </Box>}

                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CustomerInvoices