import { Box, Stack, HStack, InputGroup, InputLeftAddon, Select, AlertDialog, AlertDialogBody, 
        AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useDisclosure,
        useToast, Input, Textarea, Spacer, Button, Table, Thead, Tbody, Tr, Th, Td, TableContainer,
        Tooltip, Card, ButtonGroup, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
        ModalBody, Flex } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { TYPES } from '../actions/cartAction'
import { useTable, usePagination } from "react-table"
import { DeleteIcon } from '@chakra-ui/icons'
import { useState, useReducer, useRef } from 'react'
import { cartInitialState, cartReducer } from '../reducers/cartReducer'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import SaleProductAdd from './SaleProductAdd'
import CustomerSearch from './CustomerSearch'
import useColumnsSaleAdd from "../hooks/useColumnsSaleAdd"
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')

const SaleAdd = ({ handleSubmitModal }) => {
    const [stateReducer, dispatch] = useReducer(cartReducer, cartInitialState)
    const { data } = stateReducer
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm()
    const [saleDate, setSaleDate] = useState(new Date())
    const [grossPrice, setGrossPrice] = useState(parseFloat(0).toFixed(2))
    const [ivaPrice, setIvaPrice] = useState(parseFloat(0).toFixed(2))
    const [price, setPrice] = useState(parseFloat(0).toFixed(2))
    const { isOpen, onOpen, onClose } = useDisclosure()
    //const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
    const { isOpen: isOpenConfirmSale, onOpen: onOpenConfirmSale, onClose: onCloseConfirmSale } = useDisclosure()
    //const cancelRef = useRef()
    //const cancelRefAlert = useRef()
    const cancelRefConfirmSale = useRef()
    const toast = useToast()
    //const [isDisabled, setIsDisabled] = useState(true)
    //const [invoiceLetter, setInvoiceLetter] = useState("")
    let [idCustomer, setIdCustomer] = useState(0)
    const initialRef = useRef(null)

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.sistemacorralonbianchi.com.ar/api/sale/get/invoice/number", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const sale = data.map(sale => {
                return { 
                    invoiceNumber: sale.invoiceNumber
                }
            })
            setValue("invoiceNumber", sale[0].invoiceNumber)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        reset()
        clearCart()
        onOpen()
    }

    const columns = useColumnsSaleAdd();

    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["_id"]} }, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state
      } = table;

    const onSubmit = () => {
        moment.locale("es")

        if (data.length === 0){
            toast({
                title: "No hay productos cargados",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (saleDate === null){
            toast({
                title: "Seleccione una fecha",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        onOpenConfirmSale()
    }

    const confirmSale = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("en")
        const products = []

        for (let i=0; i < data.length; i++) {
            products.push({
                _id: data[i]._id,
                serialNumber: data[i].serialNumber,
                quantity: parseFloat(data[i].quantity),
                discount: parseFloat(data[i].discount),
                warranty: data[i].warranty,
                avgIva: parseFloat(data[i].avgIva),
                unitPrice: parseFloat(data[i].unitPrice),
                totalPrice: parseFloat(data[i].totalPrice)
            })
        }

        const sale = {
            customer: idCustomer,
            invoiceNumber: getValues("invoiceNumber"),
            invoiceLetter: "No oficial",
            date: moment(saleDate).format('L'),
            observations: getValues("observations") || "",
            grossPrice: parseFloat(grossPrice),
            ivaPrice: parseFloat(ivaPrice),
            price: parseFloat(price),
            products: products,
            cae: "",
            caeExpiration: moment(saleDate).format('L'),
            invoiceNumberAfip: "",
            dateAfip: moment(saleDate).format('L'),
            base64: "",
            isInvoice: false
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/sale", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(sale)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        reset()
        clearCart()
        //setIsDisabled(true)
        handleSubmitModal()
        onCloseConfirmSale()

        toast({
            title: "La venta se cargó correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        onClose()
    }

    /*const handleChange = (e) => {
        if (data.length > 0){
            onOpenAlert()
        } else {
            setInvoiceLetter(e.target.value)

            if (e.target.value === "") {
                setIsDisabled(true)
            } else {
                setIsDisabled(false)
            }
        }
    }*/

    const handleSelect = (idNewCustomer) => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.sistemacorralonbianchi.com.ar/api/customer/" + idNewCustomer, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setIdCustomer(idNewCustomer)
            setValue("customer", data.name)
            setValue("iva", data.ivaName)
            setValue("cuit", data.cuit)
            setValue("street", data.street)
            setValue("city", data.city)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const handleClose = () => {
        handleSubmitModal()
        onClose()
    }

    /*const handleCloseAlert = () => {
        setValue("invoiceLetter", invoiceLetter)
        onCloseAlert()
    }*/

    const addToCart = (product) => {
        /*if (getValues("invoiceLetter") === "A") {
            let totalPrice
            const unitPrice = parseFloat(product.price) / ((parseFloat(product.avgIva) / 100) + 1)
            if (parseFloat(product.discount) > 0) {
                const subtotal = parseFloat(unitPrice) - (parseFloat(unitPrice) * parseFloat(product.discount) / 100)
                totalPrice = parseFloat(product.quantity) * parseFloat(subtotal)
            } else {
                totalPrice = parseFloat(product.quantity) * parseFloat(unitPrice)
            }
            product = {
                ...product,
                unitPrice: parseFloat(unitPrice).toFixed(2),
                totalPrice: parseFloat(totalPrice).toFixed(2)
            }*/
        //} else if (getValues("invoiceLetter") === "B" || getValues("invoiceLetter") === "C" || getValues("invoiceLetter") === "No oficial") {
            let totalPrice
            if (parseFloat(product.discount) > 0) {
                const subtotal = parseFloat(product.price) - (parseFloat(product.price) * parseFloat(product.discount) / 100)
                totalPrice = parseFloat(product.quantity) * parseFloat(subtotal)
            } else {
                totalPrice = parseFloat(product.quantity) * parseFloat(product.price)
            }
            
            product = {
                ...product,
                unitPrice: parseFloat(product.price).toFixed(2),
                totalPrice: parseFloat(totalPrice).toFixed(2)
            }
        //}
        
        dispatch({ type:TYPES.ADD_TO_CART, payload: product })
        calculatingPrices(product, true)
    }

    const clearCart = () => {
        dispatch({ type:TYPES.CLEAR_CART })

        setGrossPrice(parseFloat(0).toFixed(2))
        setIvaPrice(parseFloat(0).toFixed(2))
        setPrice(parseFloat(0).toFixed(2))

        onClose()
    }

    const removeFromCart = (product) => {
        dispatch({ type:TYPES.REMOVE_ONE_FROM_CART, payload: product.id })
        calculatingPrices(product, false)
    }

    const calculatingPrices = (product, add) => {
        let sumGrossPrice = 0
        //let sumIvaPrice = 0
        /*if (getValues("invoiceLetter") === "A") {
            if (product) {
                for (let i=0; i < data.length; i++) {
                    if (data[i].id === product.id) continue
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                    sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(data[i].totalPrice) * parseFloat(data[i].avgIva) / 100)
                }

                if (add === true) {
                    const productSearch = data.find(obj => obj.id === product.id)
                    if (productSearch) {
                        const quantityProduct = parseFloat(productSearch.quantity) + parseFloat(product.quantity)
                        const grossPriceProduct = parseFloat(quantityProduct) * parseFloat(product.unitPrice)
                        const ivaPriceProduct = parseFloat(grossPriceProduct) * parseFloat(product.avgIva) / 100
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(grossPriceProduct)
                        sumIvaPrice = parseFloat(sumIvaPrice) + parseFloat(ivaPriceProduct)
                    } else {
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(product.totalPrice)
                        sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(product.totalPrice) * parseFloat(product.avgIva) / 100)
                    }
                }
            }
            setGrossPrice(parseFloat(sumGrossPrice).toFixed(2))
            setIvaPrice(parseFloat(sumIvaPrice).toFixed(2))
            setPrice(parseFloat(sumGrossPrice + sumIvaPrice).toFixed(2))*/
            
        //} else if (getValues("invoiceLetter") === "B" || getValues("invoiceLetter") === "C" || getValues("invoiceLetter") === "No oficial") {
            if (product) {
                for (let i=0; i < data.length; i++) {
                    if (data[i].id === product.id) continue
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                }
                if (add === true) {
                    const productSearch = data.find(obj => obj.id === product.id)
                    if (productSearch) {
                        const quantityProduct = parseFloat(productSearch.quantity) + parseFloat(product.quantity)
                        const grossPriceProduct = parseFloat(quantityProduct) * parseFloat(product.unitPrice)
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(grossPriceProduct)
                    } else {
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(product.totalPrice)
                    }
                }
            }
            
            setGrossPrice(parseFloat(sumGrossPrice).toFixed(2))
            setIvaPrice(parseFloat(0).toFixed(2))
            setPrice(parseFloat(sumGrossPrice).toFixed(2))
        /*} else {
            setGrossPrice(parseFloat(0).toFixed(2))
            setIvaPrice(parseFloat(0).toFixed(2))
            setPrice(parseFloat(0).toFixed(2))
        }*/
    }

    return (
        <>
            <ButtonGroup size='sm' isAttached>
                <Button colorScheme="whatsapp" onClick={handleOpen}>Venta</Button>
                <IconButton onClick={handleOpen} aria-label='Add to friends' icon={<AddIcon />} colorScheme="whatsapp" variant="outline"/>
            </ButtonGroup>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={handleClose}
                size='6xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nueva venta</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form onSubmit={handleSubmit(onSubmit)} width='500px'>
                            <Stack spacing={4}>
                                <Card align="center" variant="elevated" padding="10px">
                                    <Stack spacing={4}>
                                        <HStack>
                                            <InputGroup>
                                                <InputLeftAddon children='Cliente' width={140} />
                                                <Input isDisabled {...register('customer', {required: 'Seleccione el cliente'})} />
                                                <CustomerSearch handleSelect={handleSelect}/>
                                            </InputGroup>

                                            <InputGroup>
                                                <InputLeftAddon children='Condición I.V.A.' width={140} />
                                                <Input isDisabled {...register('iva')} />
                                            </InputGroup>
                                        </HStack>
                                        {errors.customer && <AlertPop title={errors.customer.message} />}

                                        <HStack>
                                            <InputGroup>
                                                <InputLeftAddon children='C.U.I.T.' width={140} />
                                                <Input isDisabled {...register('cuit')} />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputLeftAddon children='Dirección' width={140} />
                                                <Input isDisabled {...register('street')} />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputLeftAddon children='Ciudad' width={140} />
                                                <Input isDisabled {...register('city')} />
                                            </InputGroup>
                                        </HStack>
                                    </Stack>
                                </Card>

                                <HStack>
                                    <InputGroup>
                                        <InputLeftAddon children='N° Comprobante' width={140} />
                                        <Input isDisabled {...register('invoiceNumber')} />
                                    </InputGroup>

                                    {/*<InputGroup>
                                        <InputLeftAddon children='Letra' width={140} />
                                        <Select placeholder='-Selecciona-' {...register('invoiceLetter', {onChange: (e) => handleChange(e), required: 'Seleccione el tipo de comprobante'})}>
                                            <option value='No oficial'>No oficial</option>
                                            <option value='A'>A</option>
                                            <option value='B'>B</option>
                                            {/*<option value='C'>C</option>
                                        </Select>
                                    </InputGroup>*/}

                                    <HStack spacing={0}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fecha' width={140} />
                                        </InputGroup>
                                        <DatePicker locale='es' selected={saleDate} onChange={(date) => setSaleDate(date)} dateFormat="dd/MM/yyyy" />
                                    </HStack>

                                    <InputGroup>
                                        <InputLeftAddon children='Observaciones' width={140} />
                                        <Textarea size='sm' {...register('observations')} />
                                    </InputGroup>
                                </HStack>
                                {errors.invoiceLetter && <AlertPop title={errors.invoiceLetter.message} />}

                                {/*<HStack>
                                    <InputGroup>
                                        <InputLeftAddon children='Importe bruto $' width={140} />
                                        <Input isDisabled placeholder='$' type='number' step='0.01' value={grossPrice} />
                                    </InputGroup>

                                    <InputGroup>
                                        <InputLeftAddon children='Importe I.V.A. $' width={140} />
                                        <Input isDisabled placeholder='$' type='number' step='0.01' value={ivaPrice} />
                                    </InputGroup>
                                </HStack>*/}

                                <Spacer/>
                            </Stack>

                            <TableContainer paddingTop="15px">
                                <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                    <Thead>
                                        {
                                            // Recorremos las columnas que previamente definimos
                                            headerGroups.map(headerGroup => (
                                                // Añadimos las propiedades al conjunto de columnas
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    // Recorremos cada columna del conjunto para acceder a su información
                                                    headerGroup.headers.map((column) => (
                                                    // Añadimos las propiedades a cada celda de la cabecera
                                                    <Th {...column.getHeaderProps()} >
                                                        {
                                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                                        column.render("Header")
                                                        }
                                                    </Th>
                                                    ))
                                                }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            // Recorremos las filas
                                            page.map(row => {
                                                // Llamamos a la función que prepara la fila previo renderizado
                                                prepareRow(row);
                                                return (
                                                // Añadimos las propiedades a la fila
                                                <Tr {...row.getRowProps()}>
                                                    {
                                                    // Recorremos cada celda de la fila
                                                    row.cells.map((cell) => {
                                                        // Añadimos las propiedades a cada celda de la fila
                                                        return (
                                                        <Td {...cell.getCellProps()}>
                                                            {
                                                            // Pintamos el contenido de la celda
                                                            cell.render("Cell")
                                                            }
                                                        </Td>
                                                        );
                                                    })
                                                    }
                                                    <Td>
                                                        <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={() => removeFromCart(row.values)}><DeleteIcon/></Button></Tooltip>
                                                    </Td>
                                                </Tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>

                            <Box paddingTop="10px" textAlign="center">
                                <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                                <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                            </Box>

                            <Spacer/>

                            <Flex p={4}>
                                <Spacer/>
                                <SaleProductAdd addToCart={addToCart}/>
                                
                                <InputGroup width={300} paddingLeft="10px">
                                    <InputLeftAddon children='Importe $' width={140} />
                                    <Input isDisabled placeholder='$' type='number' step='0.01' value={price} width={140} textAlign="right" />
                                </InputGroup>
                            </Flex>

                            <Flex p={4}>
                                <Spacer/>
                                <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                            </Flex>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/*<AlertDialog
                isOpen={isOpenAlert}
                leastDestructiveRef={cancelRefAlert}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Tipo de comprobante
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Al cambiar el tipo de comprobante se vaciará la lista de productos cargados. ¿Desea continuar?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={handleCloseAlert}>
                        No
                    </Button>
                    <Button colorScheme='blue' onClick={clearCart} ml={3}>
                        Si
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
                                </AlertDialog>*/}

            <AlertDialog
                isOpen={isOpenConfirmSale}
                leastDestructiveRef={cancelRefConfirmSale}
                onClose={onCloseConfirmSale}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirmar venta
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        ¿Desea confirmar la venta?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRefConfirmSale} onClick={onCloseConfirmSale}>
                        No
                    </Button>
                    <Button colorScheme='blue' onClick={confirmSale} ml={3}>
                        Confirmar
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default SaleAdd