import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
    Input, Button, ButtonGroup, IconButton, useDisclosure, InputGroup, 
    InputLeftAddon, Stack, useToast, Select, Spacer, Flex } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const CategoryAdd = ({handleSubmitModal}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const toast = useToast()

    const navigate = useNavigate();

    const onSubmit = data => {
        const accessToken = localStorage.getItem("user")
        
        const category = {
            name: data.name
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/category", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(category)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        toast({
            title: "Categoría añadida!",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        handleSubmitModal()
        onClose()
    }

    const handleOpen = () => {
        reset()
        onOpen()
    }

    const handleClose = () => {
        reset()
        onClose()
    }

    return (
        <>
            <ButtonGroup size='sm' isAttached>
                <Button colorScheme="whatsapp" onClick={handleOpen}>Nueva categoría</Button>
                <IconButton onClick={handleOpen} aria-label='Add to friends' icon={<AddIcon />} colorScheme="whatsapp" variant="outline"/>
            </ButtonGroup>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={handleClose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nueva categoría</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={4}>

                            <InputGroup>
                                <InputLeftAddon children='Nombre' width={127} />
                                <Input ref={initialRef} placeholder='Nombre' {...register('name', {required: 'Por favor ingrese el nombre del cliente'})} />
                            </InputGroup>
                            {errors.name && <AlertPop title={errors.name.message} />}
                
                        </Stack>
                        <Flex p={4}>
                        <Spacer/>
                        <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                        <Button onClick={handleClose}>Cancelar</Button>
                        </Flex>
                        
                    </form>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default CategoryAdd