import { Box, Button, Card, useDisclosure, Divider, FormLabel, Table, Thead, Tbody, Tr, Th, Td, TableContainer, 
    HStack, CircularProgress, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader,
    AlertDialogContent, AlertDialogOverlay, Tooltip, useToast} from '@chakra-ui/react'
import 'moment/locale/es'
import { useTable, useSortBy, usePagination } from "react-table";
import { DeleteIcon } from '@chakra-ui/icons'
import useColumnsReceipts from "../hooks/useColumnsReceipts"
import PrintPDFReceipt from './PrintPDFReceipt';
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')


const Receipts = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [fromDate, setFromDate] = useState(new Date(moment().subtract(7, 'days')))
    const [toDate, setToDate] = useState(new Date())
    const [submitModal, setSubmitModal] = useState(false)
    const { isOpen: isOpenRemoveReceipt, onOpen: onOpenRemoveReceipt, onClose: onCloseRemoveReceipt } = useDisclosure()
    const cancelRefRemoveReceipt = useRef()
    const [receipt, setReceipt] = useState(null)
    const toast = useToast()

    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")
        setLoading(true)

        const newFromDate = moment(fromDate).format('L')
        const newToDate = moment(toDate).format('L')
        
        const filter = {
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-")
        }

        fetch("https://api.sistemacorralonbianchi.com.ar/api/receipt/fromDate/" + filter.fromDate + "/toDate/" + filter.toDate, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrReceipts = data.map(receipt => {
                return {
                    id: receipt._id,
                    receiptNumber: receipt.receiptNumber,
                    date: moment(receipt.date).add(3,"h").format('L'),
                    dateNoView: receipt.date,
                    customer: receipt.customer,
                    observations: receipt.observations,
                    amount: "$" + parseFloat(receipt.amount).toFixed(2)
                }
            })
            setData(arrReceipts)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })    

    }, [fromDate, toDate, submitModal])

    const columns = useColumnsReceipts();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["id", "dateNoView"], sortBy: [{id: "dateNoView", desc: true}]} }, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state
    } = table

    const handleFromDate = (date) => {
        setFromDate(date || new Date())
    }

    const handleToDate = (date) => {
        setToDate(date || new Date())
    }

    const handleRemove = (idReceipt) => {
        setReceipt(idReceipt)
        onOpenRemoveReceipt()
    }

    const removeReceipt = () => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.sistemacorralonbianchi.com.ar/api/receipt/" + receipt, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        toast({
            title: "El recibo se eliminó correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        setSubmitModal(!submitModal)
        onCloseRemoveReceipt()
    }

    return (
        <>
            <Box className="content">

                <Card align="center" variant="elevated" padding="10px">
                        <HStack>
                        <Box></Box>
                            <FormLabel>Desde:</FormLabel>
                            <DatePicker locale='es' selected={fromDate} onChange={(date) => handleFromDate(date)} dateFormat="dd/MM/yyyy" />

                            <FormLabel>Hasta:</FormLabel>
                            <DatePicker locale='es' selected={toDate} onChange={(date) => handleToDate(date)} dateFormat="dd/MM/yyyy" />
                        </HStack>
                </Card>

                <Divider paddingTop="15px"/>

                {loading && <Box paddingTop="15px" textAlign='center'> 
                    <CircularProgress isIndeterminate color='green.300' />
                </Box>}

                {!loading && <TableContainer paddingTop="15px">
                    <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                        <Thead>
                            {
                                // Recorremos las columnas que previamente definimos
                                headerGroups.map(headerGroup => (
                                    // Añadimos las propiedades al conjunto de columnas
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        // Recorremos cada columna del conjunto para acceder a su información
                                        headerGroup.headers.map((column) => (
                                        // Añadimos las propiedades a cada celda de la cabecera
                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                            {
                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                            column.render("Header")
                                            }
                                        </Th>
                                        ))
                                    }
                                    </Tr>
                                ))
                            }
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {
                                // Recorremos las filas
                                page.map(row => {
                                    // Llamamos a la función que prepara la fila previo renderizado
                                    prepareRow(row);
                                    return (
                                    // Añadimos las propiedades a la fila
                                    <Tr {...row.getRowProps()}>
                                        {
                                        // Recorremos cada celda de la fila
                                        row.cells.map((cell) => {
                                            // Añadimos las propiedades a cada celda de la fila
                                            return (
                                            <Td {...cell.getCellProps()}>
                                                {
                                                // Pintamos el contenido de la celda
                                                cell.render("Cell")
                                                }
                                            </Td>
                                            );
                                        })
                                        }
                                        <Td>
                                            <HStack>
                                                <PrintPDFReceipt idReceipt={row.values.id}/>
                                                <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={() => handleRemove(row.values.id)}><DeleteIcon/></Button></Tooltip>
                                            </HStack>
                                        </Td>
                                    </Tr>
                                    );
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>}

                {!loading && <Box paddingTop="10px" textAlign="center">
                    <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                    <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                </Box>}

            </Box>

            <AlertDialog
                    isOpen={isOpenRemoveReceipt}
                    leastDestructiveRef={cancelRefRemoveReceipt}
                    onClose={onCloseRemoveReceipt}
                >
                    <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Eliminar recibo
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Está seguro que desea eliminar el recibo?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRefRemoveReceipt} onClick={onCloseRemoveReceipt}>
                                No
                            </Button>
                            <Button colorScheme='blue' onClick={removeReceipt} ml={3}>
                                Si
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )

}

export default Receipts